const TYPEFORMID_BY_LANG = {
  es: 'WAtWLjST',
  ca: 'UsQhD6hI',
  eu: 'BWEOAHmY',
  en: 'V5OaOsSh',
};

export const getTypeformLangId = (lang: string) => {
  if (lang.startsWith('es')) return TYPEFORMID_BY_LANG['es'];
  if (lang.startsWith('en')) return TYPEFORMID_BY_LANG['en'];

  return TYPEFORMID_BY_LANG[lang] || TYPEFORMID_BY_LANG['es'];
};
