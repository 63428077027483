import styled from '@emotion/styled';

const Container = styled.div`
  height: 64px;
  width: 100%;
`;

export function Grounder() {
  return <Container />;
}
