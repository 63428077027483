import React, {
  createContext,
  useContext,
  useRef,
  useState,
  type HTMLAttributes,
  type ReactNode,
} from 'react';
import { useOnClickOutside } from '@innovamat/hooks';
import {
  DropdownContainer,
  DropdownContentContainer,
  DropdownItemContainer,
  DropdownToggleContainer,
  DropdownButton,
  Subtitle,
} from './Dropdown.styled';
import { Typography } from '../Typography';
import { IconType } from '@innovamat/glimmer-icons';
import { IconBase } from '../IconBase';
import { StateLayer } from '../../utils/common.styled';
import { ElevationType } from '../elevation';

export const STATES = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
  SELECTED: 'selected',
} as const;

export type State = (typeof STATES)[keyof typeof STATES];

type DropdownProps = {
  children: ReactNode;
  closeOnSelectItem?: boolean;
  disabled?: boolean;
  isCloseWhenClickOutsideDisabled?: boolean;
  openOnHover?: boolean;
  onToggle?: (value: boolean) => void;
};

type DropdownToggleProps = {
  label?: string;
  children?: ReactNode;
  disabled?: boolean;
  dataTestId?: string;
};

type DropdownContentProps = HTMLAttributes<HTMLDivElement> & {
  position?: 'bottomLeft' | 'bottomRight' | 'topRight' | 'topLeft';
  offset?: number;
  dataTestId?: string;
  hasScroll?: boolean;
  color?: string;
  borderRadius?: string;
  elevation?: ElevationType;
  contentSpace?: string;
  customStyles?: string;
};

export type DropdownItemProps = {
  children: ReactNode;
  subtitle?: ReactNode | string;
  state: State;
  icon?: IconType;
  onSelectItem?: () => void;
  customStyles?: string;
  dataTestId?: string;
};

export type DropdownContent = {
  opened: boolean;
  handleToggle: () => void;
  disabled?: boolean;
  closeOnSelectItem?: boolean;
  contentRef?: React.RefObject<HTMLDivElement>;
  buttonRef?: React.RefObject<HTMLDivElement>;
};

const DropdownContext = createContext<DropdownContent>({
  opened: false,
  handleToggle: () => {},
  disabled: false,
  closeOnSelectItem: false,
});

const useDropdownContext = () => useContext(DropdownContext);

const DropdownToggle = ({
  label,
  children,
  dataTestId,
}: DropdownToggleProps) => {
  const { opened, handleToggle, disabled, buttonRef } = useDropdownContext();

  const stopPropagationAndToggle = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    handleToggle();
  };

  if (children) {
    return (
      <DropdownToggleContainer
        ref={buttonRef}
        onClick={stopPropagationAndToggle}
        data-testid={dataTestId}
      >
        {children}
      </DropdownToggleContainer>
    );
  }
  return (
    <div ref={buttonRef}>
      <DropdownButton
        state={opened ? 'selected' : 'active'}
        variant="secondary"
        loading={false}
        rightIcon="ExpandMoreIcon"
        onClick={handleToggle}
        disabled={disabled}
      >
        {label}
      </DropdownButton>
    </div>
  );
};

const DropdownContent = ({
  children,
  offset,
  className,
  dataTestId,
  position = 'bottomLeft',
  hasScroll = false,
  color,
  borderRadius = '4px',
  elevation = 'elevation 2',
  contentSpace = '4px',
  customStyles,
}: DropdownContentProps) => {
  const { opened, contentRef } = useDropdownContext();
  return (
    <DropdownContentContainer
      backgroundColor={color}
      borderRadius={borderRadius}
      className={className}
      contentSpace={contentSpace}
      customStyles={customStyles}
      data-testid={dataTestId}
      elevation={elevation}
      hasScroll={hasScroll}
      isOpen={opened}
      offset={offset}
      position={position}
      ref={contentRef}
      role="listbox"
    >
      {children}
    </DropdownContentContainer>
  );
};

const DropdownItem = ({
  children,
  subtitle,
  state,
  icon,
  onSelectItem,
  customStyles,
  dataTestId,
  ...rest
}: DropdownItemProps) => {
  const { closeOnSelectItem, handleToggle } = useDropdownContext();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    onSelectItem?.();

    if (closeOnSelectItem) {
      handleToggle();
    }
  };

  return (
    <DropdownItemContainer
      className="dropdownItem"
      customStyles={customStyles}
      data-testid={dataTestId}
      onClick={handleClick}
      role="option"
      state={state}
      {...rest}
    >
      {state !== 'disabled' && (
        <StateLayer className="dropdownItem-stateLayer" />
      )}
      {icon && (
        <IconBase
          icon={icon}
          size="M"
          isHoverEnabled={false}
          className="menuIcon"
        />
      )}
      <div>
        <Typography.Body2>{children}</Typography.Body2>
        {subtitle && <Subtitle state={state}>{subtitle}</Subtitle>}
      </div>
    </DropdownItemContainer>
  );
};

export const Dropdown = ({
  children,
  closeOnSelectItem = false,
  disabled,
  isCloseWhenClickOutsideDisabled = false,
  openOnHover = false,
  onToggle,
}: React.PropsWithChildren<DropdownProps>) => {
  const [opened, setOpenDropdown] = useState<boolean>(false);
  const contentRef = useRef(null);
  const buttonRef = useRef(null);

  const handleToggle = () => {
    if (openOnHover) return;
    setOpenDropdown(!opened);
    onToggle?.(!opened);
  };

  const handleToggleOnHover = (value: boolean) => {
    if (!openOnHover) return;
    setOpenDropdown(value);
    onToggle?.(value);
  };

  useOnClickOutside(
    contentRef,
    () => {
      if (!isCloseWhenClickOutsideDisabled && opened) {
        setOpenDropdown(false);
        onToggle?.(false);
      }
    },
    buttonRef
  );

  return (
    <DropdownContext.Provider
      value={{
        opened,
        handleToggle,
        disabled,
        closeOnSelectItem,
        contentRef,
        buttonRef,
      }}
    >
      {
        <DropdownContainer
          disabled={!!disabled}
          onMouseEnter={() => handleToggleOnHover(true)}
          onMouseLeave={() => handleToggleOnHover(false)}
        >
          {children}
        </DropdownContainer>
      }
    </DropdownContext.Provider>
  );
};

Dropdown.Toggle = DropdownToggle;
Dropdown.Content = DropdownContent;
Dropdown.Item = DropdownItem;
