/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation as useLocationv6, useNavigate } from 'react-router-domv6';

import { useLocation, useHistory } from 'react-router-dom';
import {
  ListNavigation,
  NavigationEvents,
  NavigationMode,
  ResourceNavigationType,
} from '../types';

// fix temporal hasta que borremos el gestor antiguo
function useGetHistory() {
  try {
    const history = useHistory();
    const { pathname } = useLocation();
    const handleNavigate = (path: string) => {
      history.push(path);
    };
    return { handleNavigate, pathname };
  } catch {
    const navigate = useNavigate();
    const { pathname } = useLocationv6();

    const handleNavigate = (path: string) => {
      navigate(path);
    };
    return {
      handleNavigate,
      pathname,
    };
  }
}

type ResourceNavigationContextValue =
  | {
      resources: ResourceNavigationType[] | null;
      currentResourceIndex: number;
      isLastEnabledResource: () => boolean;
      isFirstEnabledResource: () => boolean;
      setResourcesNavigationList: (pageResources: ListNavigation[]) => void;
      navigateToResource: (
        topMenu: string,
        resourceId: string,
        navigationMode: NavigationMode,
        type: string,
        onNavigate?: (navigationEvents: NavigationEvents) => void
      ) => void;
      navigateToNextResource: (
        topMenu: string,
        onNavigate?: (navigationEvents: NavigationEvents) => void
      ) => void;
      navigateToPreviousResource: (
        topMenu: string,
        onNavigate?: (navigationEvents: NavigationEvents) => void
      ) => void;
    }
  | undefined;

type ResourceNavigationProviderProps = {
  children: React.ReactNode;
  undefinedRoute: string;
};

const ResourceNavigationContext =
  createContext<ResourceNavigationContextValue>(undefined);

export const ResourceNavigationProvider = ({
  children,
  undefinedRoute,
}: ResourceNavigationProviderProps) => {
  const { handleNavigate, pathname } = useGetHistory();
  const [resources, setResources] = useState<ResourceNavigationType[] | null>(
    []
  );
  const [currentResourceIndex, setCurrentResourceIndex] = useState(0);

  if (!handleNavigate) {
    throw new Error(
      'NavigationProvider must be wrapped in a <Router> from react-router-dom'
    );
  }

  const isLastEnabledResource = () => {
    if (!resources) return true;
    const lastEnabledResourceIndex = resources.reduce(
      (acc, resource, index) => {
        if (resource.enabled) return index;
        return acc;
      },
      0
    );
    return currentResourceIndex === lastEnabledResourceIndex;
  };

  const isFirstEnabledResource = () => {
    if (!resources) return true;
    const firstEnabledResourceIndex = resources.findIndex(
      (resource) => resource.enabled
    );
    return currentResourceIndex === firstEnabledResourceIndex;
  };

  const constructSessionURL = (
    type: string,
    topMenu: string,
    resourceId: string,
    fromList: string | null,
    fromPage: string | null,
    listType?: string
  ) => {
    const origin = window.location?.origin;
    const typePath =
      topMenu + `/${type}/:id`.replace(':id', encodeURIComponent(resourceId));

    if (!origin) {
      return typePath;
    }

    const typeURL = new URL(typePath, origin);

    typeURL.searchParams.append('fromList', encodeURIComponent(fromList ?? ''));

    if (listType) typeURL.searchParams.append('listType', listType);

    typeURL.searchParams.append('fromPage', encodeURIComponent(fromPage ?? ''));

    const path = type + typeURL.href.toString().split(type)[1];

    return path;
  };

  const getPath = (
    topMenu: string,
    type: string,
    resourceId: string,
    fromList: string,
    fromPage: string,
    listType?: string
  ) => {
    let path: string | undefined;

    switch (type) {
      case 'session':
        path = constructSessionURL(
          'session',
          topMenu,
          resourceId,
          fromList,
          fromPage,
          listType
        );
        break;
      case 'genially':
        path = constructSessionURL(
          'doc',
          topMenu,
          resourceId,
          fromList,
          fromPage,
          listType
        );
        break;
      case 'pdf':
        path = constructSessionURL(
          'pdf',
          topMenu,
          resourceId,
          fromList,
          fromPage,
          listType
        );
        break;
      case 'vimeo':
        path = constructSessionURL(
          'video',
          topMenu,
          resourceId,
          fromList,
          fromPage,
          listType
        );
        break;
      case 'manipulative':
      case 'applet':
        path = constructSessionURL(
          'applet',
          topMenu,
          resourceId,
          fromList,
          fromPage,
          listType
        );
        break;
      case 'geogebra':
        path = constructSessionURL(
          'geogebra',
          topMenu,
          resourceId,
          fromList,
          fromPage,
          listType
        );
        break;
      default:
        path = undefinedRoute;
        break;
    }
    if (path) {
      path = `/${topMenu}/${path}`;
    }
    return path;
  };

  const navigateToResource = (
    topMenu: string,
    resourceId: string,
    navigationMode: NavigationMode = 'selection',
    type: string,
    onNavigate?: (navigationEvents: NavigationEvents) => void
  ) => {
    if (!resources) return;
    const resourceIndex = resources?.findIndex(
      (resource) => resource.id === resourceId
    );
    if (resourceIndex < 0) return;
    setCurrentResourceIndex(resourceIndex);

    const pageId = resources[resourceIndex].fromPage;
    const listId = resources[resourceIndex].fromList;
    const listType = resources[resourceIndex].listType;
    const path = getPath(topMenu, type, resourceId, listId!, pageId!, listType);
    onNavigate?.({ resourceId, navigationMode, pageId, listId, type });
    if (path) handleNavigate(path);
  };

  const setResourcesNavigationList = (pageResources: ListNavigation[]) => {
    const resourcesNavigationList: ResourceNavigationType[] = [];
    pageResources.forEach((pageResource) => {
      pageResource.resources.forEach((resource) => {
        resourcesNavigationList.push(resource);
      });
    });
    setResources(resourcesNavigationList);
  };

  const navigateToNextResource = (
    topMenu: string,
    onNavigate?: (navigationEvents: NavigationEvents) => void
  ) => {
    if (!resources) return;
    let nextResourceIndex = currentResourceIndex + 1;
    if (nextResourceIndex >= resources.length) return;

    while (!resources[nextResourceIndex].enabled) {
      nextResourceIndex++;
      if (nextResourceIndex >= resources.length) return;
    }
    navigateToResource(
      topMenu,
      resources[nextResourceIndex].id,
      'right arrow',
      resources[nextResourceIndex].type,
      onNavigate
    );
  };

  const navigateToPreviousResource = (
    topMenu: string,
    onNavigate?: (navigationEvents: NavigationEvents) => void
  ) => {
    if (!resources) return;
    let previousResourceIndex = currentResourceIndex - 1;
    if (previousResourceIndex < 0) return;

    while (!resources[previousResourceIndex].enabled) {
      previousResourceIndex--;
      if (previousResourceIndex < 0) return;
    }
    navigateToResource(
      topMenu,
      resources[previousResourceIndex].id,
      'left arrow',
      resources[previousResourceIndex].type,
      onNavigate
    );
  };

  useEffect(() => {
    if (!resources) return;
    const currentLocation = pathname.split('/');
    const resourceId = currentLocation[currentLocation.length - 1];
    const resourceIndex = resources?.findIndex(
      (resource) => resource.id === resourceId
    );
    if (resourceIndex !== undefined) {
      setCurrentResourceIndex(resourceIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);

  const contextValue = {
    resources,
    currentResourceIndex,
    isLastEnabledResource,
    isFirstEnabledResource,
    navigateToResource,
    setResourcesNavigationList,
    navigateToNextResource,
    navigateToPreviousResource,
  };

  return (
    <ResourceNavigationContext.Provider value={contextValue}>
      {children}
    </ResourceNavigationContext.Provider>
  );
};

export const useResourceNavigation = () => {
  const context = useContext(ResourceNavigationContext);
  if (context === undefined) {
    throw new Error(
      'useResourceNavigation must be used within a ResourceNavigationProvider'
    );
  }
  return context;
};
