import { createContext, useContext, useMemo } from 'react';
import { User } from '../types';
import { useOrganizationQuery } from '@innovamat/glow-api-client';
import {
  TokenData,
  inlineManual,
  roles,
  storage,
} from '@innovamat/radiance-utils';
import {
  getSchoolName,
  getUserFromCookies,
  mapUserToInlineManualUser,
} from '../utils';
import { detectIsInIOS, detectIsInWebview } from '@innovamat/webview-bridge';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export type UserManagementProps = {
  children: React.ReactNode;
};

type UserManagementValue = {
  user: User | undefined;
  isRole: {
    Admin: boolean;
    Advisor: boolean;
    Content: boolean;
    DistrictAdmin: boolean;
    Free: boolean;
    Internal: boolean;
    Lead: boolean;
    Manager: boolean;
    Owner: boolean;
    Parent: boolean;
    Student: boolean;
    Teacher: boolean;
  };
  tokenInfo: TokenData | undefined;
  onClearUserSession: () => void;
  onForceUpdateUserValues: (user: Partial<User>) => void;
};

const UserManagementContext = createContext<UserManagementValue>({
  user: undefined,
  isRole: {
    Admin: false,
    Advisor: false,
    Content: false,
    DistrictAdmin: false,
    Free: false,
    Internal: false,
    Lead: false,
    Manager: false,
    Owner: false,
    Parent: false,
    Student: false,
    Teacher: false,
  },
  tokenInfo: undefined,
  onClearUserSession: () => {},
  onForceUpdateUserValues: () => {},
});

function UserManagementProvider({
  children,
}: UserManagementProps): JSX.Element {
  const user = getUserFromCookies();
  const { data: orgData, isSuccess: isOrgSuccess } = useOrganizationQuery({
    id: user.school,
  });
  const queryClient = useQueryClient();

  const enabledSetUser = (user.school && isOrgSuccess) || user.crmId;

  const query = useQuery({
    queryKey: ['User'],
    queryFn: async () => {
      const user = getUserFromCookies();

      if (!enabledSetUser) return;

      const newUser: User = {
        id: user.id,
        crmId: user.crmId,
        email: user.email,
        roles: user.roles,
        organizationName: '',
        organizationId: '',
        organization: undefined,
        region: user.region,
        physicalRegion: user.region,
        locale: user.locale,
        name: user.name,
        fullName: user.fullName,
        familyName: user.familyName,
      };

      if (roles.hasStudentJuniorRole(user.roles)) {
        // window.location.href = WEBAPP_URL;
      }

      if (!user.school) {
        if (!user.crmId) {
          throw new Error('No school or crmId found in user');
        }

        newUser.organizationName = await getSchoolName(user.crmId);
      } else {
        if (!orgData?.organization) {
          throw new Error('No organization data found');
        }
        const org = orgData.organization!;
        newUser.organizationName = org.name!;
        newUser.organizationId = org.id!;
        newUser.organization = {
          id: org.id!,
          location: org.location!,
          academicYearId: org.academicYearId,
          currency: org.currency!,
          platformEnabled: org.platformEnabled!,
          name: org.name!,
          standard: org.standard!,
        };

        if (org.region !== org.operativeRegion) {
          newUser.locale = org.language;
          newUser.region = org.operativeRegion!;
          newUser.physicalRegion = org.region;
        }
      }

      if (roles.hasStudentRole(user.roles)) {
        // get student data
        // set student data into user
      }

      const isInWebview = detectIsInWebview();
      const isInIOS = detectIsInIOS();

      const inlineUser = mapUserToInlineManualUser(
        newUser,
        isInIOS,
        isInWebview
      );
      inlineManual.initOrUpdateInlineManualPlayer(inlineUser);

      return newUser;
    },
    enabled: !!enabledSetUser,
  });

  const onClearUserSession = (): void => {
    storage.clear();
    queryClient.removeQueries();
    inlineManual.deleteInlineManualUser();
  };

  const isRole = useMemo(
    () => ({
      Admin: roles?.hasAdminRole(user.roles),
      Advisor: roles?.hasAdvisorRole(user.roles),
      Content: roles?.hasContentAdminRole(user.roles),
      DistrictAdmin: roles?.hasDistrictAdminRole(user.roles),
      Free: roles?.hasFreeRole(user.roles),
      Internal: roles?.hasInternalRole(user.roles),
      Lead: roles?.hasLeadRole(user.roles),
      Manager: roles?.hasManagerRoles(user.roles),
      Owner: roles?.hasOwnerRole(user.roles),
      Parent: roles?.hasParentRole(user.roles),
      Student: roles?.hasStudentRole(user.roles),
      Teacher: roles?.hasTeacherRole(user.roles),
    }),
    [user.roles]
  );

  const onForceUpdateUserValues = (user: Partial<User>) => {
    queryClient.setQueryData<User>(['User'], (prevUser) => {
      if (!prevUser) return undefined;
      return { ...prevUser, ...user };
    });
  };

  const value: UserManagementValue = {
    user: query.data,
    isRole,
    onForceUpdateUserValues,
    tokenInfo: storage.tokenInfo.get(),
    onClearUserSession,
  };

  return (
    <UserManagementContext.Provider value={value}>
      {children}
    </UserManagementContext.Provider>
  );
}

function useUser(): UserManagementValue {
  const context = useContext(UserManagementContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserManagementProvider');
  }
  return context;
}

export { UserManagementProvider, useUser };
