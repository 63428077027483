import { useLayoutEffect, useMemo, useState } from 'react';
import { createTheme, Table, TableBody, TableRow } from '@mui/material';
import { ThemeProvider as MaterialProvider } from '@mui/material/styles';
import { useGlimmerTheme } from '../../theme';
import type {
  ColumnWithActions,
  ExtendedRow,
  Order,
  TableProps,
} from './table.types';
import {
  StickyHeader,
  TableCellStyled,
  TableContainerStyled,
} from './table.styled';
import {
  getComparator,
  getLastRowSpanIndex,
  getTotalStickyWidthBeforeIndex,
  handleStickyHorizontalScroll,
} from './utils';
import { TableHeadWithSorting } from './components/table-header';
import { LineSeparator } from './components/line-separator';
import useStickyHeaderHandler from './hooks/use-sticky-header-handler';

export function TableComponent<T>({
  backgroundColor,
  columns,
  firstRow,
  id,
  rows,
  topStickyOffset,
  handleSendEvent,
  onExpandColumn,
  isNewManager,
  ...rest
}: TableProps<T>): JSX.Element {
  const materialTheme = createTheme({});
  const glimmerTheme = useGlimmerTheme();

  const theme = {
    ...materialTheme,
    breakpoints: {
      ...materialTheme.breakpoints,
      ...glimmerTheme.breakpoints,
    },
  };

  const [extendableId, setExtendableId] = useState('');
  const [sortingId, setSortingId] = useState('');
  const [sortingOrder, setSortingOrder] = useState<Order>('asc');

  const handleRequestSort = (property: string): void => {
    const isAsc = sortingId === property && sortingOrder === 'asc';
    setSortingOrder(isAsc ? 'desc' : 'asc');
    setSortingId(property);
    handleSendEvent?.('sort_by_column', {
      sorting_column: property,
      sorting_direction: isAsc ? 'desc' : 'asc',
    });
  };

  const handleExtendColumn = (property: string): void => {
    const newExtendableId = extendableId === property ? '' : property;
    setExtendableId(newExtendableId);
    onExpandColumn?.(newExtendableId, id);
  };

  // TODO: porque se hace un setSortingId('lastName') por default en un componente?
  // useLayoutEffect(() => {
  //   setSortingId('lastName');
  // }, [id]);

  const renderValueRow = (
    column: ColumnWithActions<T>,
    value: ExtendedRow<T>[keyof ExtendedRow<T>],
    row: ExtendedRow<T>,
    backgroundColor?: string
  ): string | JSX.Element => {
    if (column.separator) {
      return (
        <LineSeparator
          backgroundColor={backgroundColor}
          isDarkSeparator={column.isDarkSeparator}
        />
      );
    }

    return column.render?.(value, row) || value;
  };

  const lastRowWithRowSpanIndex = useMemo(
    () => getLastRowSpanIndex(rows, columns),
    [rows, columns]
  );

  useStickyHeaderHandler(id, topStickyOffset!, !!firstRow, isNewManager);

  useLayoutEffect(() => {
    const table = document.getElementById(id);
    table?.addEventListener('scroll', () => handleStickyHorizontalScroll(id));

    return () => {
      table?.removeEventListener('scroll', () =>
        handleStickyHorizontalScroll(id)
      );
    };
  }, [id]);

  const tableRows = useMemo(
    () => rows?.sort(getComparator(sortingOrder, sortingId, columns)),
    [columns, rows, sortingId, sortingOrder]
  );

  return (
    <MaterialProvider theme={theme}>
      <StickyHeader id={`sticky-header+${id}`}>
        <TableHeadWithSorting
          columns={columns}
          extendableId={extendableId}
          id={id}
          onExtendColumn={handleExtendColumn}
          onRequestSort={handleRequestSort}
          sortingId={sortingId}
          sortingOrder={sortingOrder}
          subHeaderRow={firstRow}
        />
      </StickyHeader>
      <div id={`table+${id}`} />
      <TableContainerStyled id={id}>
        <Table aria-label="sticky table" {...rest}>
          <TableHeadWithSorting
            backgroundColor={backgroundColor}
            columns={columns}
            extendableId={extendableId}
            id={id}
            onExtendColumn={handleExtendColumn}
            onRequestSort={handleRequestSort}
            sortingId={sortingId}
            sortingOrder={sortingOrder}
            subHeaderRow={firstRow}
          />
          <TableBody>
            {tableRows?.slice().map((row, rowIndex) => {
              return (
                <TableRow
                  key={rowIndex}
                  role="row"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  tabIndex={-1}
                  hover={true}
                >
                  {columns.map((column, index) => {
                    const value = row[column.id];
                    return (
                      !column.isHiddenColumn && (
                        <TableCellStyled
                          align={column.align}
                          backgroundColor={backgroundColor}
                          className={
                            lastRowWithRowSpanIndex === rowIndex
                              ? 'no-border-bottom'
                              : ''
                          }
                          data-testid={rowIndex}
                          isHiddenTableCell={
                            column.rowSpan && column.rowSpan(value) === 0
                          }
                          isSticky={column.sticky}
                          key={column.id}
                          left={getTotalStickyWidthBeforeIndex(columns, index)}
                          role="cell"
                          rowSpan={column.rowSpan && column.rowSpan(value)}
                          separator={column.separator}
                        >
                          {renderValueRow(column, value, row)}
                        </TableCellStyled>
                      )
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainerStyled>
    </MaterialProvider>
  );
}
