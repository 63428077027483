import styled from '@emotion/styled';
import { InputStatus } from '.';
import { Typography, getTypography } from '../Typography';
import { css } from '@emotion/react';
import { Tooltip } from '../Tooltip';

const StyledTooltip = styled(Tooltip)`
  display: inherit;
`;

const Wrapper = styled.div<{
  status?: InputStatus;
  fill?: boolean;
  keepIconColor?: boolean;
}>`
  ${({ theme, status, fill, keepIconColor }) => {
    const elementDisabled =
      theme.tokens.color.alias.cm.text['text-disabled'].value;
    const errorToken = theme.tokens.color.alias.cm.icon['icon-error'].value;
    const elementAutofilled =
      theme.tokens.color.alias.cm.bg['bg-neutral-subtle'].value;

    return css`
      display: flex;
      flex-direction: column;
      gap: 6px;
      width: ${fill ? '100%' : ''};

      ${status === 'error' &&
      `
        .inputContainer {
          outline: 1px solid ${errorToken};
          outline-offset: -1px;

          :focus-within {
            outline: 2px solid ${errorToken};
            outline-offset: -2px;
          }

          * {
            color: ${errorToken};
        }
        }

        path {
          fill: ${errorToken};
        }
        `}

      ${status === 'disabled' &&
      `
        pointer-events: none;
        * {
          color: ${elementDisabled};
        }

        .inputContainer {
          outline: 1px solid ${elementDisabled};
          outline-offset: -1px;
        }

        path {
          fill: ${keepIconColor ? undefined : elementDisabled};
        }
        `}

      ${status === 'autofilled' &&
      `
        .inputContainer {
          pointer-events: none;
          outline: 1px solid ${elementDisabled};
          outline-offset: -1px;
          background-color: ${elementAutofilled};

          input {
            color: ${elementDisabled} !important;
          }

          path {
            fill: ${keepIconColor ? undefined : elementDisabled};
          }
        }
      `}
    `;
  }}
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px;
  outline: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-default'].value};
  outline-offset: -1px;
  border-radius: 4px;

  :hover {
    outline: 1px solid
      ${({ theme }) =>
        theme.tokens.color.alias.cm.border['border-inverted'].value};
  }

  :focus-within {
    outline: 2px solid
      ${({ theme }) =>
        theme.tokens.color.alias.cm.border['border-default'].value};
    outline-offset: -2px;
  }
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  flex: 1;
  background-color: transparent;
  ${({ theme }) => getTypography(theme, 'Body 1')}

  ::placeholder {
    color: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-disabled'].value};
  }

  :disabled {
    background-color: transparent;
  }
`;

const DescriptiveText = styled(Typography.Body3)<{ isError?: boolean }>`
  color: ${({ theme, isError }) =>
    isError
      ? theme.tokens.color.alias.cm.icon['icon-error'].value
      : theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const WrapperButton = styled.button<{ onClick?: () => void }>`
  width: fit-content;
  border: none;
  background-color: transparent;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export {
  Wrapper,
  InputContainer,
  StyledInput,
  DescriptiveText,
  WrapperButton,
  StyledTooltip,
};
