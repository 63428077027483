import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ChangeEvent, InputHTMLAttributes } from 'react';

const ToggleContainer = styled.div<{
  customStyles?: string;
}>`
  position: relative;
  height: 24px;

  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
  border-radius: 100px;

  ${({ customStyles }) =>
    customStyles &&
    css`
      ${customStyles}
    `}
`;

const ToggleInput = styled.input`
  position: relative;
  opacity: 0;
  z-index: 1;
  border-radius: 100px;
  width: 48px;
  height: 24px;
  cursor: pointer;

  ${({ theme }) =>
    css`
      &:checked ~ .toggleHandle {
        margin-left: 25px;
        background-color: ${theme.tokens.color.alias.cm.icon['icon-inverted']
          .value};
      }

      &:checked ~ .toggleLabel {
        background-color: ${theme.tokens.color.alias.cm.bg['bg-accent-inverted']
          .value};
        border: 1px solid
          ${theme.tokens.color.alias.cm.bg['bg-accent-inverted'].value};
      }

      &:enabled:hover ~ .toggleLabel {
        &::after {
          background-color: ${theme.tokens.color.specific['state-layer'][
            'state-hover-darker'
          ].value};
        }
      }

      &:disabled ~ .toggleLabel {
        background-color: transparent;
        border-color: ${theme.tokens.color.alias.cm.bg['bg-disabled'].value};
      }

      &:disabled:checked ~ .toggleLabel {
        background-color: ${theme.tokens.color.alias.cm.bg['bg-disabled']
          .value};
      }

      &:disabled ~ .toggleHandle {
        background-color: ${theme.tokens.color.alias.cm.bg['bg-disabled']
          .value};
      }
    `}
`;

const ToggleLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 24px;
  border-radius: 100px;
  cursor: pointer;
  background-color: transparent;

  ${({ theme }) =>
    css`
      border: 1px solid
        ${theme.tokens.color.alias.cm.border['border-default'].value};
    `}

  ::after {
    content: '';
    display: block;
    width: 48px;
    height: 24px;
    border-radius: 100px;
    margin-top: -1px;
    margin-left: -1px;
  }
`;

const ToggleHandle = styled.div`
  position: absolute;
  top: 2px;
  margin-left: 2px;
  display: block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: 0.2s;

  ${({ theme }) =>
    css`
      background-color: ${theme.tokens.color.alias.cm.border['border-default']
        .value};
    `}
`;

interface ToggleButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  isActive?: boolean;
  disabled?: boolean;
  onToggle?: (value: boolean) => void;
  customStyles?: string;
  dataTestId?: string;
}

export function ToggleButton({
  isActive,
  disabled,
  onToggle,
  customStyles,
  dataTestId,
  ...rest
}: ToggleButtonProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;
    if (onToggle) {
      onToggle(newChecked);
    }
  };

  return (
    <ToggleContainer customStyles={customStyles}>
      <ToggleInput
        checked={isActive}
        disabled={disabled}
        onChange={handleChange}
        data-testid={dataTestId || 'toggle-input'}
        type="checkbox"
        className="toggleButton"
        {...rest}
      />
      <ToggleLabel className="toggleLabel" />
      <ToggleHandle className="toggleHandle" />
    </ToggleContainer>
  );
}
