import { useEffect, useState } from 'react';
import { LottieAnimation } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReportsLoader = (): JSX.Element => {
  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    // Dynamically import the animation JSON from the given path
    import('./reports-loader.json')
      .then((data) => {
        setAnimationData(data.default);
      })
      .catch((error) => console.error('Error loading animation data:', error));
  }, []);

  return (
    <Container>
      <LottieAnimation
        animationData={animationData}
        width={600}
        height={600}
        loop
        autoplay
      />
    </Container>
  );
};

export default ReportsLoader;
