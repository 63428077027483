import { Course } from '@innovamat/radiance-utils';
import { apolloFetch } from '../../utils/apolloFetch';

export const getCourses = async (regionCode: string): Promise<Course[]> => {
  const graphQl = JSON.stringify({
    query: `query Courses($regionCode: String!, $organizationId: ID, $active: Boolean) {
      courses(regionCode: $regionCode, organizationId: $organizationId, active: $active) {
        id
        region
        order
        name
      }
    }`,
    variables: { regionCode, active: true },
  });
  const response = await apolloFetch({
    graphQl,
  });
  return response.data?.courses;
};
