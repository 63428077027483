import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Loader } from '@innovamat/glimmer-components';
import useResourceTimer from '../../hooks/useResourceTimer';
import usePlayerState from '../../providers/PlayerProviders/usePlayerState';
import LoaderContainer from '../../components/LoaderContainer';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.js`;

type Props = {
  path: string;
  page?: number;
  numPages: number;
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const PdfWrapper = styled.div<{
  fitHeight: boolean;
  currentHeight: number | undefined;
  currentWidth: number | undefined;
}>`
  width: 100%;
  height: 100%;

  .document,
  .page {
    display: block;
    height: 100% !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  canvas {
    display: block;
    margin: 0 auto;
    ${({ fitHeight }) =>
      fitHeight
        ? css`
            height: 100% !important;
            width: auto !important;
          `
        : css`
            height: auto !important;
            width: 100% !important;
          `}
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default function PdfViewer({ path, page }: Props) {
  useResourceTimer();
  const { fullscreen } = usePlayerState();
  const { fullscreenHeight, fullscreenWidth } = {} as any;

  //2 fixes the blurred elements for retina displays.
  const [zoomScale] = useState(2);
  const [fitHeight, setFitHeight] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);

  const getSize = () => {
    const wrapper = document.getElementById('resource-wrapper');
    return {
      width: wrapper?.clientWidth || 0,
      height: wrapper?.clientHeight || 0,
    };
  };

  const { width, height } = getSize();

  const pdfRef = useRef<{
    width: number;
    height: number;
  }>();

  const onDocumentLoadSuccess = async (pdf: any) => {
    const pageView = await pdf.getPage(page);

    const pdfInitialWidth = pageView.view[2] - pageView.view[0];
    const pdfInitialHeight = pageView.view[3] - pageView.view[1];

    // save the pdf size
    pdfRef.current = {
      width: pdfInitialWidth,
      height: pdfInitialHeight,
    };

    handleFitHeight(width, height);
    setIsLoading(false);
  };

  const handleFitHeight = (width: number, height: number) => {
    if (!pdfRef.current) return;
    const pdfInitialWidth = pdfRef.current.width;
    const pdfInitialHeight = pdfRef.current.height;
    const estimatedHeight = (width * pdfInitialHeight) / pdfInitialWidth;
    setFitHeight(estimatedHeight >= height);
  };

  useEffect(() => {
    if (height !== 0 && width !== 0) {
      handleFitHeight(width, height);
    }
  }, [width, height]);

  useEffect(() => {
    setIsLoading(true);
  }, [path]);

  const handleDoubleClick = () => {
    if (fullscreen?.active) {
      fullscreen?.exit();
    } else {
      fullscreen?.enter();
    }
  };

  if (!height || width === 0) return null;

  return (
    <Wrapper>
      {isLoading && <LoaderContainer />}
      <PdfWrapper
        onDoubleClick={handleDoubleClick}
        fitHeight={fitHeight}
        currentHeight={fullscreen?.active ? fullscreenHeight : height}
        currentWidth={fullscreen?.active ? fullscreenWidth : width}
        key={path}
      >
        <InnerWrapper>
          <Document
            file={path}
            loading={<Loader />} // el component spinner cambia el height
            externalLinkTarget="_blank"
            className="document"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={page}
              scale={zoomScale}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              className="page"
              width={width || pdfRef.current?.width} // don't delete this width and height, without them
              height={height || pdfRef.current?.height} // canvas' devicePixelRatio breaks pdfs in iOS
            />
          </Document>
        </InnerWrapper>
      </PdfWrapper>
    </Wrapper>
  );
}
