import { ChangeEvent, useEffect, useState } from 'react';
import { snack } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { getRegisterLanguagesByRegion } from '@innovamat/localization';

import { isEmpty, roles } from '@innovamat/radiance-utils';

import { validators } from '@innovamat/radiance-utils';
import {
  useChangePasswordMutation,
  useSettingsQuery,
} from '@innovamat/glow-api-client';
import {
  EditSettingsValues,
  SettingsTabType,
  SettingsValues,
  User,
} from '../types';
import { useSetSettings } from './use-set-settings';

type SettingsProps = {
  user: User;
  goBack: () => void;
  onSuccess: () => void;
};

export default function useSettings({
  user,
  goBack,
  onSuccess,
}: SettingsProps) {
  const { t } = useTranslation();
  const isStudent = roles.hasStudentRole(user.roles);
  const { data: settingsData } = useSettingsQuery();
  const settings = settingsData?.settings as SettingsValues | undefined;

  const { mutate: changePassword } = useChangePasswordMutation();

  const { setSettings, isSavingSettings, isSuccessSettings } = useSetSettings({
    onSuccess,
  });

  const { region: operativeRegion, physicalRegion } = user || {};
  const userData = user;

  const showLanguageSelector =
    !isStudent && (!operativeRegion || operativeRegion === physicalRegion);

  const region = operativeRegion || physicalRegion;

  const [settingsValues, setSettingsValues] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });

  const [language, setLanguage] = useState('');
  const [positions, setPositions] = useState<string[]>();
  const allPositions = Object.entries(settings?.schoolPositions || {});
  const initialPositions = settings ? Object.keys(settings.positions) : [];
  const [phoneCode, setPhoneCode] = useState();
  const filteredLangs = getRegisterLanguagesByRegion(region)?.map((lang) => ({
    value: lang,
    label: t(`language.${lang}`),
    key: lang,
  }));

  const [someFieldChanged, setSomeFieldChanged] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [isPageLoading, setIsPageLoading] = useState(true);

  const updateField = (name: keyof typeof settingsValues, value: string) => {
    setSettingsValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleInputPhone = (e: any, country: any) => {
    if (country.dialCode !== phoneCode) {
      setPhoneCode(country.dialCode);
    }
    if (e.length === country.dialCode.length) updateField('phoneNumber', '');
    else updateField('phoneNumber', `+${e}`);
    setSomeFieldChanged(true);
  };

  const handleChangeLang = (value: string) => {
    setLanguage(value);
  };

  const getChecked = (key: string) => !!positions?.some((p) => p === key);

  const handleChangeCheckbox = (role: string) => {
    setPositions((prevPositions) =>
      prevPositions?.includes(role)
        ? prevPositions.filter((p) => p !== role)
        : [...(prevPositions || []), role]
    );
  };

  const isPasswordValid = (_password: string) => {
    if (_password.length === 0) return true;
    return /^\S*(?=\S{6,})(?=\S*[a-zA-Z])(?=\S*[\d])\S*$/.test(_password);
  };

  const isConfirmPasswordValid = (_confirmPassword: string) => {
    if (password.length === 0 && _confirmPassword.length === 0) return true;
    return password === _confirmPassword;
  };

  const getConfirmPasswordErrorMessage = () => {
    if (!isPasswordValid(confirmPassword))
      return t('settings.change_password.error');
    if (!isConfirmPasswordValid(confirmPassword))
      return t('settings.change_password.not_match');

    return undefined;
  };

  const hasPasswordChangedAndIsValid = (): boolean =>
    password.length !== 0 &&
    confirmPassword.length !== 0 &&
    isPasswordValid(password) &&
    isConfirmPasswordValid(confirmPassword);

  const hasPasswordChangeAndIsInvalid = (): boolean =>
    (password.length !== 0 || confirmPassword.length !== 0) &&
    (!isPasswordValid(password) || !isConfirmPasswordValid(confirmPassword));

  const handleChangePassword = async (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleChangeConfirmPassword = async (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleChangePasswordRequest = async (
    password: string,
    onlyPasswordHasChanged: boolean
  ) => {
    if (userData && userData.id) {
      const userId = userData.id;
      try {
        changePassword({ id: userId, password });
        if (onlyPasswordHasChanged)
          snack.success(t("Settings.Els canvis s'han desat correctament."));
      } catch (error) {
        snack.error(t('error.changePassword'));
      }
      setPassword('');
      setConfirmPassword('');
    }
  };

  const hasEmptyFields = () => {
    const { firstName, lastName } = settingsValues;
    return isEmpty(firstName) || isEmpty(lastName) || isEmpty(language);
  };

  const checkEnableButtons = () => {
    if (!validators.isPhoneValid(settingsValues.phoneNumber, phoneCode)) {
      return false;
    }

    if (hasEmptyFields()) return false;
    return true;
  };

  const onCancel = () => {
    goBack();
  };

  const onSaveChanges = () => {
    if (!settings) return;

    setSomeFieldChanged(false);

    const newSettings = {
      firstName: settingsValues.firstName,
      lastName: settingsValues.lastName,
      phoneNumber: settingsValues.phoneNumber,
      positions: positions,
    };

    const newSettingsValues = {
      settings: newSettings,
      language,
    };

    const onlyPasswordHasChanged =
      settings.firstName === newSettings.firstName &&
      settings.lastName === newSettings.lastName &&
      settings.phoneNumber === newSettings.phoneNumber &&
      JSON.stringify(initialPositions) === JSON.stringify(positions) &&
      userData?.locale === language;

    if (hasPasswordChangedAndIsValid())
      handleChangePasswordRequest(password, onlyPasswordHasChanged);

    if (onlyPasswordHasChanged) return;
    setSettings(newSettingsValues as EditSettingsValues); // TODO: Check positions type
  };

  useEffect(() => {
    if (!settings || !userData) setIsPageLoading(true);
    if (settings && userData) {
      setSettingsValues({
        firstName: settings.firstName || '',
        lastName: settings.lastName || '',
        phoneNumber: settings.phoneNumber || '',
      });
      setLanguage(userData.locale || '');
      setPositions(Object.keys(settings.positions));
      setIsPageLoading(false);
    }
  }, [settings, userData]);

  useEffect(() => {
    setSomeFieldChanged(true);
  }, [password, confirmPassword]);

  useEffect(() => {
    setSomeFieldChanged(
      JSON.stringify(positions) !== JSON.stringify(initialPositions) ||
        settingsValues.firstName !== settings?.firstName ||
        settingsValues.lastName !== settings?.lastName ||
        settingsValues.phoneNumber !== settings?.phoneNumber ||
        userData?.locale !== language
    );
  }, [settingsValues, language, positions]);

  const isLoading = isSavingSettings;

  const [activeTab, setActiveTab] = useState<SettingsTabType>('PersonalData');
  const [contentDrawerIsOpen, setContentDrawerOpen] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const getTitle = (tab: SettingsTabType): string => {
    switch (tab) {
      case 'PersonalData':
        return t('settings.personalData');
      case 'Password':
        return t('settings.change_password');
      case 'Email':
        return t('settings.accounts_connected.title');
      case 'SchoolData':
        return t('settings.SchoolData');
      case 'Language':
        return t('Settings.Language');
    }
  };

  const getCurrentTitle = () => getTitle(activeTab);

  const isTabActive = (tab: string): boolean => {
    return activeTab === tab;
  };

  const changeActiveTab = (newTab: SettingsTabType): void => {
    setActiveTab(newTab);
    setContentDrawerOpen(true);
    setShowDrawer(true);
  };

  const onCloseContentDrawer = () => {
    setContentDrawerOpen(false);
  };

  const [showModalSave, setShowModalSave] = useState<boolean>(false);
  const onGoBack = () => {
    setShowModalSave(someFieldChanged);
    if (!someFieldChanged) goBack();
  };
  const onCloseModal = () => {
    setShowModalSave(false);
  };

  return {
    schoolName: userData?.organizationName,
    userEmail: userData?.email,
    showLanguageSelector,
    settingsValues,
    phoneCode,
    filteredLangs,
    country: region?.toLowerCase(),
    language,
    password,
    confirmPassword,
    allPositions,
    isPageLoading,
    isValueEmpty: isEmpty,
    isPhoneValid: validators.isPhoneValid,
    isPasswordValid,
    isConfirmPasswordValid,
    getConfirmPasswordErrorMessage,
    getChecked,
    updateField,
    handleInputPhone,
    handleChangeLang,
    handleChangePassword,
    handleChangeConfirmPassword,
    handleChangeCheckbox,
    onCancel,
    onSaveChanges,
    isSubmitEnabled:
      !checkEnableButtons() ||
      isLoading ||
      !someFieldChanged ||
      hasPasswordChangeAndIsInvalid(),
    isLoading,
    changeActiveTab,
    isTabActive,
    getTitle,
    getCurrentTitle,
    showModalSave,
    onCloseModal,
    onGoBack,
    contentDrawerIsOpen,
    onCloseContentDrawer,
    showDrawer,
    isSavingSettings,
    isSuccessSettings,
  };
}
