import { Suspense, lazy, useEffect } from 'react';
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { inlineManual } from '@innovamat/radiance-utils';
import { useUpdateEffect } from '@innovamat/hooks';

import styles from './App.module.scss';
import useAuthentication from '../../hooks/useAuthentication';
import HomeLayout from '../HomeLayout';

import { authPaths, otherPaths } from '../../routes';
import useAppHeight from '../../hooks/useAppHeight';

import '../../styles/_global.scss';
import 'katex/dist/katex.min.css';
import 'react-toastify/dist/ReactToastify.css';

import { ErrorBoundary } from 'react-error-boundary';
import E500 from '../Errors/E500';
import { ImportsWidget } from '@innovamat/ga-components';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../hooks/use-user';

const Login = lazy(() => import('../Login'));

function ErrorHandler({ error }: { error: Error }) {
  return <E500 error={error} />;
}

function LazyLogin() {
  return (
    <Suspense fallback={<div></div>}>
      <Login />
    </Suspense>
  );
}

function App() {
  useAuthentication();
  useAppHeight();

  const { user, isRole, isLoggedIn, organizationId } = useUser();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  useUpdateEffect(() => {
    inlineManual.updateInlinePlayer();
  }, [location]);

  useEffect(() => {
    getReferalToRedirect();
    if (!isLoggedIn) {
      redirectToLoginWithReferal();
    }
  }, [isLoggedIn]);

  const match = (paths: typeof authPaths | typeof otherPaths) =>
    !!paths.filter(({ path }) => matchPath(location.pathname, { path })).length;

  const getReferalToRedirect = () => {
    const referalPath = new URLSearchParams(location.search).get('rf');
    if (referalPath && isLoggedIn) {
      if (referalPath.includes('/manager')) {
        window.location.href = referalPath;
      } else {
        history.replace(referalPath);
      }
    }
  };

  const redirectToLoginWithReferal = () => {
    const isAuthPath = match(authPaths);
    const isOtherPath = match(otherPaths);
    if (isAuthPath && !isOtherPath) {
      history.push(
        `/?rf=${encodeURIComponent(location.pathname + location.search)}`
      );
    }
  };

  const currentPaths = isLoggedIn ? authPaths : otherPaths;

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <div className={styles.App} data-testid="app">
        <Switch>
          {currentPaths.map(({ path, component }) => (
            <Route key={path} component={component} path={path} />
          ))}

          <Route
            path={['/', '/curriculum', '/practice', '/learning']}
            component={isLoggedIn ? HomeLayout : LazyLogin}
          />
        </Switch>
        <ImportsWidget
          translate={t}
          isAdminOrOwner={isRole.Admin || isRole.Owner}
          organizationId={user?.organizationId}
        />
      </div>
    </ErrorBoundary>
  );
}

export default App;
