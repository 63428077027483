import { Region } from './_generated/locales';
import { LANGUAGES } from './languages';

export const PREPR_LANGUAGES_MAPPER = {
  ca: 'ca-ES',
  en: 'en-GB',
  en_US: 'en-US',
  es_CL: 'es-CL',
  es_CO: 'es-CO',
  es_EC: 'es-EC',
  es: 'es-ES',
  es_MX: 'es-MX',
  eu: 'eu-ES',
  it_IT: 'it-IT',
  pt_BR: 'pt-BR',
  va: 'va-ES',
  es_419: 'es-LA',
};

type PreprKeys = keyof typeof PREPR_LANGUAGES_MAPPER;
type PreprValues = (typeof PREPR_LANGUAGES_MAPPER)[PreprKeys];

export function getLocalesForPrepr(
  region: Region | undefined,
  locale: string
): PreprValues[] {
  if (!region) {
    throw new Error('Region is not defined');
  }
  const { fallback } = LANGUAGES.region[region].locale[locale];

  const mappedFallback: PreprValues[] = fallback.map(
    (f: PreprKeys) => PREPR_LANGUAGES_MAPPER[f]
  );

  const currentLanguage = PREPR_LANGUAGES_MAPPER[locale as PreprKeys];

  const fallbacks = [currentLanguage, ...mappedFallback];

  return fallbacks;
}
