import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Tag } from '../../components/Tag';
import Block from './Block';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media print {
    gap: 4px;
  }
`;

type Props = {
  tags?: string[];
};

export function Standards({ tags }: Props) {
  const { t } = useTranslation();

  if (!tags || !tags.length) {
    return null;
  }

  return (
    <Block title={t('digitalguides.standards')}>
      <Container>
        {tags.map((tag: string, index) => {
          return <Tag key={`${tag}-${index}`} text={tag} isEnabled={true} />;
        })}
      </Container>
    </Block>
  );
}
