import type { AnchorHTMLAttributes, HTMLAttributes } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import type { SerializedStyles, Theme } from '@emotion/react';

export interface ParagraphProps extends HTMLAttributes<HTMLParagraphElement> {
  color?: string;
}

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  color?: string;
}

export interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  color?: string;
}

type Anchor = React.FC<AnchorProps>;
type Heading = React.FC<HeadingProps>;
type Paragraph = React.FC<ParagraphProps>;

type TypographyType =
  | keyof Theme['tokens']['typography']['Desktop']
  | keyof Theme['tokens']['typography']['Mobile'];

export const getTypography = (
  theme: Theme,
  type: TypographyType,
  color?: string
): SerializedStyles => {
  const desktopTheme = theme.tokens.typography.Desktop[type].value;
  const mobileTheme = theme.tokens.typography.Mobile[type].value;

  const hasLetterSpacing =
    'letterSpacing' in desktopTheme && 'letterSpacing' in mobileTheme;
  const hasTextCase = 'textCase' in desktopTheme && 'textCase' in mobileTheme;
  const hasTextDecoration =
    'textDecoration' in desktopTheme && 'textDecoration' in mobileTheme;

  return css`
    font-family: '${desktopTheme.fontFamily}', sans-serif;
    font-size: ${desktopTheme.fontSize};
    line-height: ${desktopTheme.lineHeight};
    font-weight: ${desktopTheme.fontWeight};
    font-style: normal;
    ${color ? `color: ${color};` : ''}

    ${hasLetterSpacing && `letter-spacing: ${desktopTheme.letterSpacing};`}
    ${hasTextCase && `text-transform: ${desktopTheme.textCase};`}
    ${hasTextDecoration && `text-decoration: ${desktopTheme.textDecoration};`}

    @media (max-width: ${theme.breakpoints.sm}) {
      font-family: '${mobileTheme.fontFamily}', sans-serif;
      font-size: ${mobileTheme.fontSize};
      line-height: ${mobileTheme.lineHeight};
      font-weight: ${mobileTheme.fontWeight};

      ${hasLetterSpacing && `letter-spacing: ${mobileTheme.letterSpacing};`}
      ${hasTextCase && `text-transform: ${mobileTheme.textCase};`}
      ${hasTextDecoration && `text-decoration: ${mobileTheme.textDecoration};`}
    }
  `;
};

const StyledH1 = styled.h1<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'H1', color)}
`;

const StyledH2 = styled.h2<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'H2', color)}
`;

const StyledH3 = styled.h3<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'H3', color)}
`;

const StyledH4 = styled.h4<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'H4', color)}
`;

const StyledSubtitle1 = styled.h5<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'Subtitle 1', color)}
`;

const StyledSubtitle2 = styled.h6<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'Subtitle 2', color)}
`;

const StyledBody1 = styled.p<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'Body 1', color)}
`;

const StyledBody2 = styled.p<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'Body 2', color)}
`;

const StyledBody3 = styled.p<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'Body 3', color)}
`;

const StyledCaption = styled.p<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'Caption', color)}
`;

const StyledSuperTitle = styled.p<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'Super Title', color)}
`;

const StyledLink1 = styled.a<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'link 1', color)}
`;

const StyledLink2 = styled.a<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'link 2', color)}
`;

const StyledLink3 = styled.a<{ color?: string }>`
  ${({ theme, color }) => getTypography(theme, 'link 3', color)}
`;

const H1: Heading = ({ children, ...props }: HeadingProps) => {
  return <StyledH1 {...props}>{children}</StyledH1>;
};

const H2: Heading = ({ children, ...props }: HeadingProps) => {
  return <StyledH2 {...props}>{children}</StyledH2>;
};

const H3: Heading = ({ children, ...props }: HeadingProps) => {
  return <StyledH3 {...props}>{children}</StyledH3>;
};

const H4: Heading = ({ children, ...props }: HeadingProps) => {
  return <StyledH4 {...props}>{children}</StyledH4>;
};

const Subtitle1: Heading = ({ children, ...props }: HeadingProps) => {
  return <StyledSubtitle1 {...props}>{children}</StyledSubtitle1>;
};

const Subtitle2: Heading = ({ children, ...props }: HeadingProps) => {
  return <StyledSubtitle2 {...props}>{children}</StyledSubtitle2>;
};

const Body1: Paragraph = ({ children, ...props }: ParagraphProps) => {
  return <StyledBody1 {...props}>{children}</StyledBody1>;
};

const Body2: Paragraph = ({ children, ...props }: ParagraphProps) => {
  return <StyledBody2 {...props}>{children}</StyledBody2>;
};

const Body3: Paragraph = ({ children, ...props }: ParagraphProps) => {
  return <StyledBody3 {...props}>{children}</StyledBody3>;
};

const Caption: Paragraph = ({ children, ...props }: ParagraphProps) => {
  return <StyledCaption {...props}>{children}</StyledCaption>;
};

const SuperTitle: Paragraph = ({ children, ...props }: ParagraphProps) => {
  return <StyledSuperTitle {...props}>{children}</StyledSuperTitle>;
};

const Link1: Anchor = ({ children, ...props }: AnchorProps) => {
  return <StyledLink1 {...props}>{children}</StyledLink1>;
};

const Link2: Anchor = ({ children, ...props }: AnchorProps) => {
  return <StyledLink2 {...props}>{children}</StyledLink2>;
};

const Link3: Anchor = ({ children, ...props }: AnchorProps) => {
  return <StyledLink3 {...props}>{children}</StyledLink3>;
};

export const Typography = (): null => {
  return null;
};

Typography.H1 = H1;
Typography.H2 = H2;
Typography.H3 = H3;
Typography.H4 = H4;
Typography.Subtitle1 = Subtitle1;
Typography.Subtitle2 = Subtitle2;
Typography.Body1 = Body1;
Typography.Body2 = Body2;
Typography.Body3 = Body3;
Typography.Caption = Caption;
Typography.SuperTitle = SuperTitle;
Typography.Link1 = Link1;
Typography.Link2 = Link2;
Typography.Link3 = Link3;
