import styled from '@emotion/styled';
import { ImageComponent } from '../image-component';
import { Icon } from '../Icon';
import { useGlimmerTheme } from '../../theme';
import { normalizeImgSource } from '../../utils/normalize-image-source';
import { Tooltip } from '../Tooltip';
import { css } from '@emotion/react';
import { IconType } from '@innovamat/glimmer-icons';
import { SemanticType } from '../../hooks';
import { StateLayer } from '../../utils/common.styled';

const SemanticIconWrapper = styled.div`
  position: absolute;
  z-index: 11;
  top: -4px;
  right: -4px;
`;

const ImageContainer = styled.div<{
  status?: SemanticType | 'selected';
  onClick?: () => void;
  hasTooltip?: boolean;
}>`
  position: relative;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 8px;

  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.bg['bg-neutral-subtle'].value};

  ${({ status }) =>
    status &&
    status !== 'selected' &&
    css`
      outline: 4px solid white;
      outline-offset: -4px;
    `};

  ${({ hasTooltip, theme, onClick }) =>
    (hasTooltip || onClick) &&
    theme &&
    css`
      cursor: pointer;

      :hover .avatar-state-layer {
        border-radius: 8px;
        background-color: ${theme.tokens.color.specific['state-layer'][
          'state-hover-darker'
        ].value};
      }
    `};
`;

const SemanticBorder = styled.div<{
  status?: SemanticType | 'selected';
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 2px solid transparent;
  z-index: 10;

  ${({ status, theme }) => {
    switch (status) {
      case 'selected':
        return `border: 2px solid ${theme.tokens.color.alias.cm.icon['icon-accent'].value};`;
      case 'imported':
        return `border: 2px solid ${theme.tokens.color.alias.cm.icon['icon-imported'].value};`;
      case 'info':
        return `border: 2px solid ${theme.tokens.color.alias.cm.icon['icon-info'].value};`;
      default:
        return `border: 2px solid transparent;`;
    }
  }};
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled(ImageComponent)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
`;

type Props = {
  avatar?: string;
  tooltipText?: string;
  onClick?: () => void;
  status?: SemanticType | 'selected';
  icon?: IconType;
};

function ClassroomAvatar({
  avatar,
  tooltipText,
  onClick,
  status,
  icon,
}: Props): JSX.Element {
  const theme = useGlimmerTheme();

  const getColorBySemanticStatus = () => {
    if (!status || status === 'selected') return;
    return theme.tokens.color.alias.cm.icon[`icon-${status}`].value;
  };

  return (
    <Tooltip content={tooltipText}>
      <ImageContainer
        className="avatar-container"
        onClick={onClick}
        status={status}
        hasTooltip={!!tooltipText}
      >
        <SemanticBorder status={status} />
        <StateLayer className="avatar-state-layer" />
        <ImageWrapper>
          {avatar && (
            <Image src={normalizeImgSource(avatar)} alt="Class avatar" />
          )}
          {icon && <Icon icon={icon} size="M" />}
        </ImageWrapper>
        {status && status !== 'selected' && (
          <SemanticIconWrapper>
            <Icon
              icon="InfoIcon"
              iconColor={
                theme.tokens.color.alias.cm.surface['surface-primary'].value
              }
              bgColor={getColorBySemanticStatus()}
              size="M"
            />
          </SemanticIconWrapper>
        )}
      </ImageContainer>
    </Tooltip>
  );
}

export { ClassroomAvatar };
