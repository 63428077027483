import React, { useState, useMemo } from 'react';

type BaseImageProps = {
  src: string;
  quality?: number;
  placeholder?: string;
  isLazyLoading?: boolean;
  alt?: string;
};

type OptimizedImageProps = BaseImageProps & {
  hasOptimization: true;
  baseUrl: string;
};

type NonOptimizedImageProps = BaseImageProps & {
  hasOptimization?: false;
};

export type ImageProps =
  | (OptimizedImageProps & React.HTMLProps<HTMLImageElement>)
  | (NonOptimizedImageProps & React.HTMLProps<HTMLImageElement>);

function isOptimizedProps(props: ImageProps): props is OptimizedImageProps {
  return !!props.hasOptimization;
}

function getImageUrl(baseUrl: string, src: string): string {
  return `${baseUrl}/_next/image?url=${encodeURIComponent(src)}`;
}

export function ImageComponent(props: ImageProps): JSX.Element {
  const {
    src,
    quality = 75,
    placeholder,
    alt,
    isLazyLoading = false,
    hasOptimization = false,
    ...rest
  } = props;

  const [loaded, setLoaded] = useState(false);

  let baseUrl = '';
  let imgProps = { ...rest };

  if (isOptimizedProps(props)) {
    baseUrl = props.baseUrl;
    const { baseUrl: _, hasOptimization: __, ...optimizedRestProps } = props;
    imgProps = optimizedRestProps;
  }

  const optimizedSrc = isOptimizedProps(props)
    ? `${getImageUrl(baseUrl, src)}&w=3840&q=${quality}`
    : src;

  const isAbsoluteUrl = /^(https?:)\/\//i.test(src);

  const widths = [640, 750, 828, 1080, 1200, 1920, 2048, 3840];
  const srcset = useMemo(() => {
    if (isOptimizedProps(props)) {
      return widths
        .map(
          (width) =>
            `${getImageUrl(baseUrl, src)}&w=${width}&q=${quality} ${width}w`
        )
        .join(', ');
    }
    return undefined;
  }, [baseUrl, src, quality]);

  const currentSrc = loaded || !placeholder ? optimizedSrc : placeholder;

  const imageStyles = useMemo(() => {
    if (!loaded && placeholder) {
      return { filter: 'blur(5px)' };
    }
    return {};
  }, [loaded, placeholder]);

  return (
    <>
      {isAbsoluteUrl ? (
        <img
          {...imgProps}
          alt={alt || ''}
          src={currentSrc}
          srcSet={srcset}
          sizes={hasOptimization ? '100vw' : undefined}
          onLoad={() => setLoaded(true)}
          loading={isLazyLoading ? 'lazy' : 'eager'}
          style={imageStyles}
        />
      ) : (
        <img {...imgProps} alt={alt || ''} src={src} />
      )}
    </>
  );
}
