import { authAxiosInstance } from '@innovamat/ga-features';
import { authService, storage } from '@innovamat/radiance-utils';
import React, { createContext, useContext, useEffect, useState } from 'react';

declare global {
  interface Window {
    __GA_CONFIG__: RegionalConfigVariablesType;
  }
}

interface LoadedConfig {
  VITE_APOLLO_SERVER: { [key: string]: string };
  VITE_API_ORGANIZATION: { [key: string]: string };
  VITE_API_USERS: { [key: string]: string };
}

interface GeoLocation {
  country: string;
  region: string;
}

const geolocation = async (fastApiUrl: string): Promise<GeoLocation> => {
  const url = `${fastApiUrl}/api/geolocation`;
  const response = await authAxiosInstance({ url, method: 'GET' });

  return response.data as GeoLocation;
};

export const isUSRegion = async (fastApiUrl: string): Promise<boolean> => {
  let region: string | null = '';
  try {
    const decodedToken = authService.decodeToken(storage.tokenInfo.get()!);
    region = decodedToken.region;
  } catch (_error) {
    const query = new URLSearchParams(window.location.search);
    const queryRegion =
      query.get('pr') || query.get('r') || query.get('region');
    region = queryRegion;
    if (!region) {
      try {
        const geoRegion = await geolocation(fastApiUrl);
        region = geoRegion.country;
      } catch (error) {}
    }
  }
  return region?.toLocaleLowerCase() === 'us';
};

const loadConfig = async (environment: string): Promise<LoadedConfig> => {
  let config;
  try {
    config = await import(`../config/variables-by-region.${environment}.json`);
  } catch (error) {
    console.error('Failed to load configuration:', error);
    throw error;
  }
  return config;
};

const initialConfig = {
  apolloServer: '',
  apiOrganization: '',
  apiUsers: '',
};

window.__GA_CONFIG__ = initialConfig;

export type RegionalConfigVariablesType = typeof initialConfig;

const RegionalConfigVariablesContext =
  createContext<RegionalConfigVariablesType>(initialConfig);

interface RegionalConfigVariablesProviderProps {
  environment: string;
  region: string;
  children: React.ReactNode;
}

export const RegionalConfigVariablesProvider = ({
  environment,
  region,
  children,
}: RegionalConfigVariablesProviderProps): JSX.Element => {
  const [config, setConfig] = useState<RegionalConfigVariablesType | null>(
    null
  );

  useEffect(() => {
    const fetchConfig = async (): Promise<void> => {
      try {
        const loadedConfig = await loadConfig(environment);
        setConfig({
          apolloServer: loadedConfig.VITE_APOLLO_SERVER[region],
          apiOrganization: loadedConfig.VITE_API_ORGANIZATION[region],
          apiUsers: loadedConfig.VITE_API_USERS[region],
        });
      } catch (error) {
        console.error('Error loading configuration:', error);
      }
    };

    fetchConfig();
  }, [environment, region]);

  useEffect(() => {
    if (config) {
      window.__GA_CONFIG__ = config;
    }
  }, [config]);

  if (!config) {
    return <></>;
  }

  return (
    <RegionalConfigVariablesContext.Provider value={config}>
      {children}
    </RegionalConfigVariablesContext.Provider>
  );
};

export const useRegionalConfigVariables = (): RegionalConfigVariablesType => {
  return useContext(RegionalConfigVariablesContext);
};
