import { EnvVariablesValues } from '@innovamat/ga-features';
import type { RegionalConfigVariablesType } from '@innovamat/regional-config-variables';

export function initEnvVariables(
  regionalConfigVariables: RegionalConfigVariablesType
): EnvVariablesValues {
  return {
    APOLLO_SERVER: regionalConfigVariables.apolloServer,
    API_USERS: regionalConfigVariables.apiUsers,
    USERS_CLIENT_ID: import.meta.env.VITE_USERS_CLIENT_ID as string,
    GA: import.meta.env.VITE_GA as string,
    CONFIG_CAT_KEY: import.meta.env.VITE_CONFIG_CAT_KEY as string,
    CONFIG_CAT_CACHE_TIME: import.meta.env.VITE_CONFIG_CAT_CACHE_TIME as string,
    WEBAPP: import.meta.env.VITE_STUDENTS_WEB_APP as string,
    INTEGROMAT_GET_CRM_SCHOOL: import.meta.env
      .VITE_INTEGROMAT_GET_CRM_SCHOOL as string,
    POEDITOR_API_URL: import.meta.env.VITE_POEDITOR_API_URL as string,
    POEDITOR_API_TOKEN: import.meta.env.VITE_POEDITOR_API_TOKEN as string,
    POEDITOR_PROJECT_ID: import.meta.env.VITE_POEDITOR_PROJECT_ID as string,
    RUDDERSTACK_APIKEY: import.meta.env.VITE_RUDDERSTACK_APIKEY as string,
    RUDDERSTACK_URL: import.meta.env.VITE_RUDDERSTACK_URL as string,
    VERSION: import.meta.env.VITE_VERSION as string,
    ITERABLE_APIKEY: import.meta.env.VITE_ITERABLE_APIKEY as string,
    INLINE_MANUAL: import.meta.env.VITE_INLINE_MANUAL as string,
    ORGANIZATIONS: regionalConfigVariables.apiOrganization,
    API_PLATFORM: import.meta.env.VITE_API_PLATFORM as string,
    STANDALONE_BUILD_DOMAIN: import.meta.env
      .VITE_UNITY_STANDALONE_BUILD_DOMAIN as string,
    UNITY_BUILD: import.meta.env.VITE_UNITY_BUILD as string,
    WEBCONTENTS_URL: import.meta.env.VITE_WEBCONTENTS_URL as string,
    API_SOLVER_URL: import.meta.env.VITE_API_SOLVER_URL as string,
    CORPORATE_WEBSITE: import.meta.env.VITE_CORPORATE_WEBSITE as string,
    INTEGROMAT_SESSION_ERROR: import.meta.env
      .VITE_INTEGROMAT_SESSION_ERROR as string,
    INTEGROMAT_SESSION_REVIEW: import.meta.env
      .VITE_INTEGROMAT_SESSION_REVIEW as string,
    GOOGLE_CLIENT_ID: import.meta.env.VITE_GOOGLE_CLIENT_ID as string,
    GOOGLE_SECRET: import.meta.env.VITE_GOOGLE_SECRET as string,
  };
}
