import { IconType } from '@innovamat/glimmer-icons';
import { useGlimmerTheme } from '../theme';

export type SemanticType =
  | 'error'
  | 'imported'
  | 'info'
  | 'success'
  | 'warning';

type ReturnType = {
  getBackground: (type?: SemanticType) => string;
  getColor: (type?: SemanticType) => string;
  getIcon: (type: SemanticType) => IconType;
};

export function useSemantic(): ReturnType {
  const theme = useGlimmerTheme();

  const bgToken = theme.tokens.color.alias.cm.bg;
  const iconToken = theme.tokens.color.alias.cm.icon;

  const defaultColor = theme.tokens.color.global.neutral.neutral500.value;
  const defaultBackground =
    theme.tokens.color.alias.cm.surface['surface-secondary'].value;

  const semanticIcons = {
    error: 'ErrorIcon',
    imported: 'InfoIcon',
    info: 'InfoIcon',
    success: 'CheckIcon',
    warning: 'WarningIcon',
  } as const;

  function getBackground(type?: SemanticType): string {
    return !type ? defaultBackground : bgToken[`bg-${type}`].value;
  }

  function getColor(type?: SemanticType): string {
    return !type ? defaultColor : iconToken[`icon-${type}`].value;
  }

  function getIcon(type: SemanticType): IconType {
    return !type ? 'InfoIcon' : (semanticIcons[type] as IconType);
  }

  return { getBackground, getColor, getIcon };
}
