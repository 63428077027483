import Cookies from 'universal-cookie/es6';
import { decryptWithAES, encryptWithAES } from './crypto';
import { TokenData } from './types/user';
import { getDomain } from './environment';
import { Classroom } from './types/classroom';
import { Course } from './types/course';

export const StorageKeys = {
  Region: 'region',
  Language: 'language',
  CurrentClassroom: 'currentClassroom',
  CurrentCourse: 'currentCourse',
  OrganizationName: 'organizationName',
  Version: 'version',
  Device: 'device',
  AccessToken: 'inv_at',
  RefreshToken: 'inv_rt',
  IdToken: 'inv_it',
  ExpiresIn: 'inv_ei',
  TokenType: 'inv_tt',
};

const YEAR = 365 * 24 * 60 * 60;

export const getCookie = (key: string): string | null => {
  try {
    const cookies = new Cookies();
    const value = cookies.get(key);
    return value ? decryptWithAES(value) : null;
  } catch (error) {
    throw new Error(`Error getting cookie: ${error}`);
  }
};

export const setCookie = (
  key: string,
  value: string,
  domain?: string
): void => {
  const cookies = new Cookies();

  cookies.set(key, encryptWithAES(value), {
    path: '/',
    domain,
    maxAge: YEAR,
  });
};

export const removeCookie = (key: string, domain?: string): void => {
  const cookies = new Cookies();
  cookies.remove(key, {
    path: '/',
    domain,
    maxAge: YEAR,
  });
};

const setInLocalStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

const getFromLocalStorage = (key: string): string | null => {
  return localStorage.getItem(key);
};

const removeFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const region = {
  get: (): string | null => getFromLocalStorage(StorageKeys.Region),
  set: (region: string): void => setInLocalStorage(StorageKeys.Region, region),
  remove: (): void => removeFromLocalStorage(StorageKeys.Region),
};

export const language = {
  get: () => getFromLocalStorage(StorageKeys.Language),
  set: (language: string) => setInLocalStorage(StorageKeys.Language, language),
  remove: () => removeFromLocalStorage(StorageKeys.Language),
};

export const classroom = {
  get: (): Classroom | null => {
    const classroom = getFromLocalStorage(StorageKeys.CurrentClassroom);
    if (!classroom) return null;
    return JSON.parse(classroom);
  },
  set: (classroom: Classroom): void =>
    setInLocalStorage(StorageKeys.CurrentClassroom, JSON.stringify(classroom)),
  remove: (): void => removeFromLocalStorage(StorageKeys.CurrentClassroom),
};

export const course = {
  get: (): Course | null => {
    const course = getFromLocalStorage(StorageKeys.CurrentCourse);
    if (!course) return null;
    return JSON.parse(course);
  },
  set: (course: Course): void =>
    setInLocalStorage(StorageKeys.CurrentCourse, JSON.stringify(course)),
  remove: (): void => removeFromLocalStorage(StorageKeys.CurrentCourse),
};

export const organizationName = {
  get: (): string | null => getFromLocalStorage(StorageKeys.OrganizationName),
  set: (organizationName: string): void =>
    setInLocalStorage(StorageKeys.OrganizationName, organizationName),
  remove: (): void => removeFromLocalStorage(StorageKeys.OrganizationName),
};

const sessionStorageKey = 'tokenData';

export const isLogoutInManager = (): boolean => {
  const accessToken = getCookie(StorageKeys.AccessToken);
  const storedTokenData = sessionStorage.getItem(sessionStorageKey);
  return Boolean(!accessToken && storedTokenData);
};

export const tokenInfo = {
  get: (): TokenData | undefined => {
    // Attempt to retrieve the token data from sessionStorage
    if (typeof sessionStorage !== 'undefined') {
      const storedTokenData = sessionStorage.getItem(sessionStorageKey);
      if (storedTokenData) {
        return JSON.parse(storedTokenData);
      }
    }

    // Retrieve tokens from cookies if not found in sessionStorage
    const accessToken = getCookie(StorageKeys.AccessToken);
    const refreshToken = getCookie(StorageKeys.RefreshToken);
    const idToken = getCookie(StorageKeys.IdToken);
    const tokenType = getCookie(StorageKeys.TokenType);
    const expiresIn = getCookie(StorageKeys.ExpiresIn);

    if (accessToken && refreshToken && idToken && tokenType && expiresIn) {
      const tokenData = {
        access_token: accessToken,
        refresh_token: refreshToken,
        id_token: idToken,
        token_type: tokenType,
        expires_in: +expiresIn,
      };

      // Store the token data in sessionStorage
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(tokenData));

      return tokenData;
    }

    // Return undefined if any of the tokens are missing
    return undefined;
  },
  set: (auth: TokenData, domain?: string): void => {
    tokenInfo.setOnlyInCookie(auth, domain);

    // Also update sessionStorage
    const sessionStorageKey = 'tokenData';
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(auth));
  },

  setOnlyInCookie: (auth: TokenData, domain?: string): void => {
    setCookie(StorageKeys.AccessToken, auth.access_token, domain);
    setCookie(StorageKeys.RefreshToken, auth.refresh_token, domain);
    setCookie(StorageKeys.IdToken, auth.id_token, domain);
    setCookie(StorageKeys.TokenType, auth.token_type, domain);
    setCookie(StorageKeys.ExpiresIn, `${auth.expires_in}`, domain);
  },

  remove: (domain?: string): void => {
    removeCookie(StorageKeys.AccessToken, domain);
    removeCookie(StorageKeys.RefreshToken, domain);
    removeCookie(StorageKeys.IdToken, domain);
    removeCookie(StorageKeys.TokenType, domain);
    removeCookie(StorageKeys.ExpiresIn, domain);

    // Also clear sessionStorage
    const sessionStorageKey = 'tokenData';
    sessionStorage.removeItem(sessionStorageKey);
  },
};

export const version = {
  get: (): number | null => {
    const version = getCookie(StorageKeys.Version);
    return version != null ? parseInt(version, 10) : null;
  },
  set: (version: string): void => setCookie(StorageKeys.Version, version),
  remove: (): void => removeCookie(StorageKeys.Version),
  current: (): number => 1,
};

export const device = {
  get: (): string | null => getCookie(StorageKeys.Device),
  set: (device: string): void => setCookie(StorageKeys.Device, device),
  remove: (): void => removeCookie(StorageKeys.Device),
};

export const clear = (clearVersion = false): void => {
  region.remove();
  language.remove();
  classroom.remove();
  course.remove();
  organizationName.remove();
  tokenInfo.remove();
  device.remove();

  const domain = getDomain();
  tokenInfo.remove(domain);

  // dejar la version entre logouts tiene sentido?
  if (clearVersion) {
    version.remove();
  }
};

//TODO: necesitamos esto?
const currentVersion = 1;

export const setCurrentVersion = (): void => {
  const current = currentVersion.toString();
  version.set(current);
};
