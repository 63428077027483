import { useQuery, useQueryClient } from '@tanstack/react-query';
import { authRouteWithoutAuth } from '../../routes';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import {
  Classroom,
  Course,
  Roles,
  Stage,
  storage,
  roles,
  stages,
  authService,
} from '@innovamat/radiance-utils';

import type { User } from './user.d';

import { getPreferences, getSchoolName } from './api';
import { runInitExternalLibraries, setOrganization, setStudent } from './user';
import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';

export function useUser() {
  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();

  const { apiUsers } = useRegionalConfigVariables();

  const isNotAuthRoute = useMemo(
    () => authRouteWithoutAuth.some((path) => location.pathname.includes(path)),
    [location.pathname]
  );

  const tokenInfo = storage.tokenInfo.get();

  const query = useQuery<User>({
    queryKey: ['user'],
    queryFn: async () => {
      const decodedToken = authService.decodeToken(storage.tokenInfo.get()!);

      let user: User = {
        id: decodedToken.sub,
        email: decodedToken.email,
        firstName: decodedToken.given_name,
        lastName: decodedToken.family_name,
        name: decodedToken.name,
        roles: decodedToken.roles,
        crmId: decodedToken.crm_id,
        organizationId: decodedToken.school,
        organizationName: '',
        organization: undefined,
        language: decodedToken.locale,
        navigation: {
          currentClassroom: undefined,
          currentCourse: undefined,
        },
        region: decodedToken.region,
        phisicalRegion: decodedToken.region,
      };

      // roles
      const isStudentJunior = roles.hasStudentJuniorRole(decodedToken.roles);
      const isUserLead = roles.hasLeadRole(decodedToken.roles);
      const isFree = roles.hasFreeRole(decodedToken.roles);
      const isStudent = roles.hasStudentRole(decodedToken.roles);
      const isTeacher = roles.hasTeacherRole(decodedToken.roles);

      // logout if user is student junior
      if (isStudentJunior) {
        onLogout();
      }

      // if the user has crmId we need to get the school name in integromat, because the school name is not in the token
      if (decodedToken.crm_id && !isTeacher) {
        user.organizationName = await getSchoolName(decodedToken.crm_id);
      }

      // whe need to get the trialExpirationDate if the user is a lead or free to display a banner
      if (isUserLead || isFree) {
        const preferences = await getPreferences(apiUsers);

        if (preferences) {
          user.trialExpirationDate = preferences.trialExpirationDate;
        }
      }

      // get organization and use organization fields if needed
      if (user.organizationId) {
        user = await setOrganization(user);
      }

      // in this point we can persist language and region for axios requests
      storage.region.set(user.region);
      storage.language.set(user.language);

      //if is Student we get the currentCourse and Classroom from the api
      if (isStudent) {
        const { currentClassroom, currentCourse } = await setStudent(
          user.id,
          user.region
        );
        user.navigation.currentCourse = currentCourse;
        user.navigation.currentClassroom = currentClassroom;
      } else {
        user.navigation.currentClassroom = storage.classroom.get() || undefined;
        user.navigation.currentCourse = storage.course.get() || undefined;
      }

      runInitExternalLibraries(user);

      return user;
    },
    enabled: Boolean(tokenInfo?.access_token && !isNotAuthRoute),
    staleTime: Infinity,
  });

  const isRole = useMemo(
    () => ({
      Teacher: roles?.hasTeacherRole(query.data?.roles),
      Owner: roles?.hasOwnerRole(query.data?.roles),
      Admin: roles?.hasAdminRole(query.data?.roles),
      Student: roles?.hasStudentRole(query.data?.roles),
      Lead: roles?.hasLeadRole(query.data?.roles),
      Free: roles?.hasFreeRole(query.data?.roles),
      Parent: roles?.hasParentRole(query.data?.roles),
      Advisor: roles?.hasAdvisorRole(query.data?.roles),
      Content: roles?.hasContentAdminRole(query.data?.roles),
      Internal: roles?.hasInternalRole(query.data?.roles),
      Manager: roles?.hasManagerRoles(query.data?.roles),
      DistrictAdmin: roles?.hasDistrictAdminRole(query.data?.roles),
    }),
    [query.data?.roles]
  );

  const updateLanguage = useCallback(
    (language: string) => {
      queryClient.setQueryData<User | undefined>(['user'], (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          language,
        };
      });

      storage.language.set(language);
    },
    [queryClient]
  );

  const updateRoles = useCallback(
    (roles: Roles[]) => {
      queryClient.setQueryData<User | undefined>(['user'], (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          roles,
        };
      });
    },
    [queryClient]
  );

  const onSetCourse = useCallback(
    (course: Course) => {
      storage.course.set(course);

      queryClient.setQueryData<User | undefined>(['user'], (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          navigation: {
            ...oldData.navigation,
            currentCourse: course,
            educationLevel: stages.getStageFromOrder(course.order) as Stage,
          },
        };
      });
    },
    [queryClient]
  );

  const onResetUrl = useCallback((): void => {
    try {
      const topMenu = location.pathname.split('/')[1];

      let url = '/curriculum/home';
      if (topMenu && topMenu !== '') {
        url = `/${topMenu}/home`;
      }
      history.push(url);
    } catch (error) {
      console.error(error);
    }
  }, [history, location.pathname]);

  const onChangeClassroom = useCallback(
    (classroom: Classroom) => {
      onResetUrl();
      queryClient.setQueryData<User | undefined>(['user'], (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          navigation: {
            ...oldData.navigation,
            currentClassroom: classroom,
          },
        };
      });

      storage.classroom.set(classroom);

      const course: Course = {
        id: classroom.courseId,
        name: classroom.courseName,
        order: classroom.courseOrder,
        region: query.data?.region || '',
      };

      if (course) {
        onSetCourse(course);
      }
    },
    [onResetUrl, onSetCourse, query.data?.region, queryClient]
  );

  const onChangeCourse = useCallback(
    (course: Course) => {
      onResetUrl();
      onSetCourse(course);
      // Remove selected classroom from cache and cookies
      storage.classroom.remove();

      queryClient.setQueryData<User | undefined>(['user'], (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          navigation: {
            ...oldData.navigation,
            currentClassroom: undefined,
          },
        };
      });
    },
    [onResetUrl, onSetCourse, queryClient]
  );

  const isLoggedIn = Boolean(tokenInfo?.access_token || query.data?.id);

  const onLogout = useCallback(() => {
    storage.clear();
    queryClient.setQueryData<User | undefined>(['user'], undefined);
    queryClient.removeQueries();
    history.push('/');
  }, [queryClient]);

  const getEducationalLevel = useCallback((): Stage | undefined => {
    if (!query.data?.navigation.currentCourse) return undefined;

    return stages.getStageFromOrder(
      query.data?.navigation.currentCourse?.order
    ) as Stage;
  }, [query.data?.navigation.currentCourse]);

  const value = useMemo(
    () => ({
      tokenInfo,
      isLoggedIn,
      ...query.data,
      ...query.data?.navigation,
      classroomId: query.data?.navigation.currentClassroom?.id,
      courseOrder: query.data?.navigation.currentCourse?.order!,
      user: query.data,
      userId: query.data?.id,
      language: query.data?.language || 'es',
      // ver por que depende donde se envia tiene que tener un nombre u otro
      educationalLevel: getEducationalLevel(),
      educationLevel: getEducationalLevel(),
      isRole,
      updateRoles,
      updateLanguage,
      onLogout,
      onChangeClassroom,
      onChangeCourse,
    }),
    [
      tokenInfo,
      isLoggedIn,
      query.data,
      getEducationalLevel,
      isRole,
      updateRoles,
      updateLanguage,
      onLogout,
      onChangeClassroom,
      onChangeCourse,
    ]
  );

  return value;
}
