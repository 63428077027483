import { TableHead, TableRow } from '@mui/material';

import {
  Column,
  ColumnWithActions,
  ExtendedRow,
  Row,
  TableHeader,
} from '../table.types';
import {
  SubLabel,
  HeaderTableCellStyled,
  TableCellFirstRow,
  ExtendIcon,
  TableSortLabelWrapper,
  TableFirstRow,
  LabelWithIconArrow,
  Separator,
  SeparatorWrapper,
  Title,
  LabelInfoIcon,
  SortIcon,
  TitleContainer,
  SublabelContainer,
} from '../table.styled';

import { getColumnWidth, getTotalStickyWidthBeforeIndex } from '../utils';
import { Tooltip } from '../../Tooltip';
import { useGlimmerTheme } from '../../../theme';
import { InfoColumnIcon } from './info-column-icon';
import { LineSeparator } from './line-separator';

const MIN_LABEL_CHARACTERS = 15;

export function TableHeadWithSorting<T>({
  sortingOrder,
  sortingId,
  onRequestSort,
  onExtendColumn,
  extendableId,
  columns,
  subHeaderRow,
  id,
  backgroundColor,
}: TableHeader<T>): JSX.Element {
  const createSortHandler = (property: string): void => {
    onRequestSort(property);
  };

  const theme = useGlimmerTheme();

  const renderValueRow = (
    column: ColumnWithActions<T>,
    value: string,
    row: ExtendedRow<T>
  ): string | any => {
    if (column.separator)
      return (
        <LineSeparator
          backgroundColor={backgroundColor}
          isDarkSeparator={column.isDarkSeparator}
        />
      );
    return column.render?.(value, row) || value;
  };

  const handleExtendBlockContentColumns =
    (id: string, extendable?: boolean) => () => {
      if (extendable) return onExtendColumn(id);
    };

  const getSubLabelText = (
    column: ColumnWithActions<T>
  ): string | JSX.Element | JSX.Element[] | undefined => {
    if (typeof column.subLabel === 'function') {
      return column.subLabel(column);
    }
    if (typeof column.subLabel === 'object') {
      return column.subLabel.text;
    }
    return column.subLabel;
  };

  const renderTitles = (column: ColumnWithActions<T>): JSX.Element => {
    const {
      sorteable,
      dataTestId,
      id,
      label,
      labelTooltip,
      extendable,
      infoColumn,
      subLabel,
    } = column;

    return (
      <>
        {sorteable ? (
          <TableSortLabelWrapper
            active={sortingId === id}
            direction={sortingId === id ? sortingOrder : 'asc'}
            onClick={() => createSortHandler(id)}
            IconComponent={() => (
              <SortIcon
                icon="ArrangeIcon"
                size="M"
                active={sortingId === id}
                direction={sortingOrder}
              />
            )}
            data-testid={`${id}_${dataTestId}`}
          >
            <LabelWithIconArrow
              largeLabel={label!.length > MIN_LABEL_CHARACTERS}
            >
              {label}
            </LabelWithIconArrow>
          </TableSortLabelWrapper>
        ) : (
          <TitleContainer>
            <Title
              data-testid={`${id}_${dataTestId}`}
              onClick={handleExtendBlockContentColumns(id, extendable)}
            >
              <Tooltip
                content={labelTooltip && labelTooltip}
                popperOptions={{ strategy: 'fixed' }}
              >
                <span>{label}</span>
              </Tooltip>
              {infoColumn && (
                <LabelInfoIcon>
                  <InfoColumnIcon tooltipText={infoColumn} />
                </LabelInfoIcon>
              )}
            </Title>

            {subLabel && renderSublabels(column)}
          </TitleContainer>
        )}
      </>
    );
  };

  const renderSublabels = (column: ColumnWithActions<T>): JSX.Element => {
    const { id, extendable, numberOfApplets, numberOfContents } = column;
    const appletsAvailable = numberOfApplets! > 0;
    const contentsAvailable = numberOfContents! > 0;
    const hasExtendableContent = appletsAvailable || contentsAvailable;
    const showExtendableIcon = extendable && hasExtendableContent;

    return (
      <SublabelContainer
        onClick={handleExtendBlockContentColumns(id, extendable)}
        isNotExtendableColumn={!extendable}
        hasExtendableContent={hasExtendableContent}
      >
        <SubLabel color={theme.tokens.color.alias.cm.text['text-subtle'].value}>
          {getSubLabelText(column)}
        </SubLabel>
        {showExtendableIcon && (
          <ExtendIcon
            icon="OpenAppletsIcon"
            isOpenColumn={extendableId === id}
            size="S"
          />
        )}
      </SublabelContainer>
    );
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => {
          return (
            !column.isHiddenColumn && (
              <HeaderTableCellStyled
                hasSubheader={!!subHeaderRow}
                key={`header-${column.id}`}
                sortDirection={sortingId === column.id ? sortingOrder : false}
                left={getTotalStickyWidthBeforeIndex(columns, index)}
                isSticky={column.sticky}
                style={{
                  width: getColumnWidth(column.width, column.id, extendableId),
                  maxWidth: getColumnWidth(
                    column.width,
                    column.id,
                    extendableId
                  ),
                  minWidth: column.minWidth || column.width,
                }}
                separator={column.separator}
                backgroundColor={backgroundColor}
              >
                {column.separator ? (
                  <SeparatorWrapper>
                    <Separator isDarkSeparator={column.isDarkSeparator} />
                  </SeparatorWrapper>
                ) : (
                  <>{renderTitles(column)}</>
                )}
              </HeaderTableCellStyled>
            )
          );
        })}
      </TableRow>

      {subHeaderRow && (
        <TableFirstRow>
          {columns.map((column, index) => {
            const value = subHeaderRow[column.id];
            return (
              !column.isHiddenColumn && (
                <TableCellFirstRow
                  key={`row-${column.id}`}
                  align={column.align}
                  isSticky={column.sticky}
                  left={getTotalStickyWidthBeforeIndex(columns, index)}
                  separator={column.separator}
                  style={{
                    minWidth: getColumnWidth(column.width, column.id),
                    display: index === 1 ? 'none' : '',
                  }}
                  colSpan={index === 0 ? 2 : 1}
                >
                  {renderValueRow(column, value, subHeaderRow)}
                </TableCellFirstRow>
              )
            );
          })}
        </TableFirstRow>
      )}
    </TableHead>
  );
}
