import styled from '@emotion/styled';

import { ClassesEmptyStateImage } from '@innovamat/glimmer-assets';
import { ImageComponent, Typography } from '@innovamat/glimmer-components';
import { normalizeImgSource } from '../../../../utils';
import { useTranslation } from 'react-i18next';

type Props = {
  goToJoinClassroomsButton: JSX.Element;
};

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    padding-bottom: 16px;
  }
`;

const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
  }
`;

const ErrorTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: flex-start;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    text-align: center;
    align-items: center;
  }
`;

const ErrorTextSubtitle = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const EmptyImage = ClassesEmptyStateImage as any;
const ImageSrc = normalizeImgSource(EmptyImage);

export function NoClassesEmptyState({
  goToJoinClassroomsButton,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <ErrorWrapper>
      <ImageComponent
        src={ImageSrc}
        width={177}
        alt="No tienes clases asignadas"
      />
      <RightSideWrapper>
        <ErrorTextWrapper>
          <Typography.H4>{t('my-classes.no-classes.title')}</Typography.H4>
          <ErrorTextSubtitle>
            {t('my-classes.no-classes.description')}
          </ErrorTextSubtitle>
        </ErrorTextWrapper>
        {goToJoinClassroomsButton}
      </RightSideWrapper>
    </ErrorWrapper>
  );
}
