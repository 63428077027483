import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0 16px;
`;

export const MainContent = styled.div`
  display: flex;
  gap: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 65%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`;
