import { InputRangeSlider } from '@innovamat/innova-components';
import React, { useEffect, useState } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import DefaultAvatar from './default-avatar.svg';
import { Modal } from '../modal';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const InputContainer = styled.div`
  cursor: pointer;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  input {
    display: none;
  }
  color: ${({ theme }) => theme.tokens.color.specific.element.link.value};
`;

const RangeContainer = styled.div`
  width: 160px;
`;

type AvatarEditorProps = {
  open: boolean;
  img?: string | File | null;
  onClose: () => void;
  onUploadImg: (img: File | undefined) => void;
  onSaveImg: (img: string) => void;
  t: (key: string) => string;
  isClassroom?: boolean;
};

export const AvatarEditor = ({
  open,
  img,
  onClose,
  onUploadImg,
  onSaveImg,
  t,
  isClassroom,
}: AvatarEditorProps) => {
  const editor = React.createRef<ReactAvatarEditor>();
  const [scale, setScale] = useState(1.0);

  useEffect(() => {
    setScale(1.0);
  }, [open]);

  const handleClickInputFile = () => {
    document.getElementById('inputfile')?.click();
  };

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    const result = editor.current?.getImageScaledToCanvas().toDataURL();
    if (result) {
      onSaveImg(result);
    }
  };

  return (
    <Modal
      isOpen={open}
      title={t('common.addImage') as string}
      buttons={[
        {
          children: t('Settings.Guardar'),
          variant: 'accent',
          onClick: handleSave,
        },
        {
          children: t('Settings.salir_sin_guardar'),
          variant: 'tertiary',
          onClick: handleClose,
        },
      ]}
      onClose={handleClose}
    >
      <Container>
        <ReactAvatarEditor
          ref={editor}
          image={img || DefaultAvatar}
          width={128}
          height={128}
          border={50}
          color={[196, 196, 196, 0.6]}
          scale={scale}
          rotate={0}
          borderRadius={isClassroom ? 16 : 100}
        />
        <RangeContainer>
          <InputRangeSlider
            disabled={!img}
            max={3}
            min={0.5}
            onChange={(value) => setScale(value)}
            step={0.1}
            value={scale}
          />
        </RangeContainer>
        <InputContainer role="presentation" onClick={handleClickInputFile}>
          <input
            id="inputfile"
            type="file"
            onChange={(e) => e?.target?.files && onUploadImg(e.target.files[0])}
          />
          {t('common.uploadImage')}
        </InputContainer>
      </Container>
    </Modal>
  );
};
