import AvatarS1 from '../../../assets/icons/iconsClassesSecundaria/1s.png';
import AvatarS2 from '../../../assets/icons/iconsClassesSecundaria/2s.png';
import AvatarS3 from '../../../assets/icons/iconsClassesSecundaria/3s.png';
import AvatarS4 from '../../../assets/icons/iconsClassesSecundaria/4s.png';
import AvatarS5 from '../../../assets/icons/iconsClassesSecundaria/5s.png';
import AvatarS6 from '../../../assets/icons/iconsClassesSecundaria/6s.png';
import AvatarS7 from '../../../assets/icons/iconsClassesSecundaria/7s.png';
import AvatarS8 from '../../../assets/icons/iconsClassesSecundaria/8s.png';
import AvatarS9 from '../../../assets/icons/iconsClassesSecundaria/9s.png';

import Avatar1 from '../../../assets/icons/iconsClasses/1.png';
// import Avatar2 from '../../../assets/icons/iconsClasses/2.png';
import Avatar3 from '../../../assets/icons/iconsClasses/3.png';
import Avatar4 from '../../../assets/icons/iconsClasses/4.png';
import Avatar5 from '../../../assets/icons/iconsClasses/5.png';
import Avatar6 from '../../../assets/icons/iconsClasses/6.png';
import Avatar7 from '../../../assets/icons/iconsClasses/7.png';
import Avatar8 from '../../../assets/icons/iconsClasses/8.png';
import Avatar9 from '../../../assets/icons/iconsClasses/9.png';
import Avatar10 from '../../../assets/icons/iconsClasses/10.png';
import Avatar11 from '../../../assets/icons/iconsClasses/11.png';
import Avatar12 from '../../../assets/icons/iconsClasses/12.png';
import Avatar13 from '../../../assets/icons/iconsClasses/13.png';
import Avatar14 from '../../../assets/icons/iconsClasses/14.png';
import Avatar15 from '../../../assets/icons/iconsClasses/15.png';
import Avatar16 from '../../../assets/icons/iconsClasses/16.png';
import Avatar17 from '../../../assets/icons/iconsClasses/17.png';
import Avatar18 from '../../../assets/icons/iconsClasses/18.png';
import Avatar19 from '../../../assets/icons/iconsClasses/19.png';
import { Classroom, stages } from '@innovamat/radiance-utils';

export type AvatarOptions = {
  [key: number]: any;
};

export const avatarsClasses: AvatarOptions = {
  1: Avatar1,
  // 2: Avatar2,
  3: Avatar3,
  4: Avatar4,
  5: Avatar5,
  6: Avatar6,
  7: Avatar7,
  8: Avatar8,
  9: Avatar9,
  10: Avatar10,
  11: Avatar11,
  12: Avatar12,
  13: Avatar13,
  14: Avatar14,
  15: Avatar15,
  16: Avatar16,
  17: Avatar17,
  18: Avatar18,
  19: Avatar19,
};

export const avatarsClassesSecundaria: AvatarOptions = {
  1: AvatarS1,
  2: AvatarS2,
  3: AvatarS3,
  4: AvatarS4,
  5: AvatarS5,
  6: AvatarS6,
  7: AvatarS7,
  8: AvatarS8,
  9: AvatarS9,
};

export function getAvatar(index: number, courseOrder: number) {
  const avatars = getAvatars(courseOrder);
  return avatars[index];
}

export function getAvatarByClassroom(classroom: Classroom) {
  if (
    classroom.avatar === '' ||
    classroom.avatar === null ||
    classroom.avatar === undefined
  ) {
    const avatars = getAvatars(classroom.courseOrder);
    return avatars[classroom.icon];
  }

  return classroom.avatar;
}

export function getAvatars(courseOrder: number) {
  const list = stages.isSecundaria(courseOrder)
    ? avatarsClassesSecundaria
    : avatarsClasses;
  return list;
}
