import styled from '@emotion/styled';
import { ModalV2 as Modal } from '@innovamat/innova-components';
import DimensionsBlock from '../../components/DimensionsBlock';
import SimpleBlock from '../../components/SimpleBlock';
import ContentBlock from '../../components/ContentBlock';
import ChallengesBlock from '../../components/ChallengesBlock/ChallengesBlock';
import AssetBlock from '../../components/AssetBlock';
import useEarlyYearGuide from './context/useEarlyYearGuide';
import VariationsBlock from '../../components/VariationsBlock';
import EarlyYearGuideProvider from './context/EarlyYearGuideProvider';
import DisclaimerBlock from '../../components/DisclaimerBlock';
import ImagesModal, {
  ImageModal,
} from '../../components/ImagesModal/ImagesModal';
import { ScrollPosition } from '../../types/prepr';
import { ChallengesResponse } from './types/challenges';
import 'tippy.js/dist/tippy.css';
import { EarlyYearDigitalGuide } from './types/earlyYearDigitalGuide';
import { Content, MainContent, Wrapper } from '../../components/Layout';
import { Separator } from '../../components/Separator';
import Heading from '../../components/Heading';
import {
  EARLY_YEAR_CONTENT_BLOCKS,
  EARLY_YEAR_CONTENT_BLOCKS_ICONS,
} from './EarlyYear.mappers';
import { Resource } from '@innovamat/resource-viewer';
import { theme } from '@innovamat/glimmer-components';
import { TFunction } from 'i18next';
import { useDigitalGuides } from '../../context';
import { Standards } from '../../components/Standards';

const AssetContent = styled.div`
  flex: 1;
`;

export const ModalContent = styled.div`
  position: relative;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: -1px;
  top: -22px;
  svg rect {
    fill: ${theme.colors.white};
  }
`;

type Props = {
  resource: Resource;
  guide: EarlyYearDigitalGuide;
  images: ImageModal[];
  canMarkAsDone?: boolean;
  onMarkChallengeAsDone?: (challengeId: string, value: boolean) => void;
  scrollPosition: ScrollPosition;
  challenges?: ChallengesResponse | undefined;
  onEvent: (key: string, data: any) => void;
  t: TFunction<'translation', undefined, 'translation'>;
};

function App() {
  const { guide, resource, images, defaultImage, onClickAsset, t } =
    useEarlyYearGuide();

  const { relatedItemsComponent } = useDigitalGuides();

  const linkedLists =
    'linkedLists' in resource ? resource.linkedLists : undefined;

  if (!guide) return null;

  return (
    <>
      <Wrapper>
        <MainContent>
          <Content>
            <SimpleBlock
              title={t('digitalguides.ey.summary')}
              text={guide.summary}
            />
            <ContentBlock
              contentIcons={EARLY_YEAR_CONTENT_BLOCKS_ICONS}
              contentBlocks={guide.contentBlocks}
              items={EARLY_YEAR_CONTENT_BLOCKS}
            />
            <Standards tags={guide.standards} />
            <DimensionsBlock
              dimensions={guide.dimensions}
              title={t('digitalguides.processes')}
            />
            <DisclaimerBlock disclaimer={guide.dimensionsDisclaimer} />
            <ChallengesBlock
              challenges={guide.challenges}
              contentIcons={EARLY_YEAR_CONTENT_BLOCKS_ICONS}
            />
          </Content>
          <AssetContent>
            <AssetBlock onClickAsset={onClickAsset} />
          </AssetContent>
        </MainContent>
        <VariationsBlock variations={guide.variations} />
        {linkedLists && linkedLists.length > 0 && (
          <>
            <Separator />
            <Heading>{t('Sessio.RelatedItems')}</Heading>
            {relatedItemsComponent}
          </>
        )}
      </Wrapper>
      <Modal
        open={!!defaultImage}
        showSubmitBtn={false}
        showCancelBtn={false}
        backgroundColor="transparent"
        overlayBgOpacity={0.8}
        clickOutsideClose
        onClose={() => {
          onClickAsset(null);
        }}
        padding="4px"
      >
        <ImagesModal
          listOfImages={images}
          defaultImage={defaultImage}
          onClickImage={onClickAsset}
        />
      </Modal>
    </>
  );
}

function EarlyYearDigitalGuideWrapper({
  guide,
  resource,
  images,
  canMarkAsDone,
  onMarkChallengeAsDone,
  scrollPosition,
  challenges,
  onEvent,
  t,
}: Props) {
  return (
    <EarlyYearGuideProvider
      challenges={challenges}
      guide={guide}
      images={images}
      canMarkAsDone={canMarkAsDone}
      resource={resource}
      onMarkChallengeAsDone={onMarkChallengeAsDone}
      scrollPosition={scrollPosition}
      onEvent={onEvent}
      t={t}
    >
      <App />
    </EarlyYearGuideProvider>
  );
}

export default EarlyYearDigitalGuideWrapper;
