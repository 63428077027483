import { LANGUAGES } from './languages';
import { createAcceptLanguage } from './utils';

export function getAcceptLanguage(
  region: string | null,
  locale: string
): string {
  if (!region) {
    throw new Error('Region is not defined');
  }

  const { fallback } = LANGUAGES.region[region].locale[locale];

  return createAcceptLanguage(locale, fallback);
}
