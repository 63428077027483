import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Block from './Block';
import { ContentBlocks } from '../types/contentBlocks';
import { getIconFromKey } from '../utils/mapIcons';
import { IconRecord } from '../types/icons';
import { useDigitalGuides } from '../context';
import { PrintableBody2 } from '../../printable-styles/printable-typography';

type Props = {
  contentBlocks: ContentBlocks;
  items: Record<string, string>;
  contentIcons: IconRecord;
  isPrintable?: boolean;
};

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 8px;

  @media print {
    gap: 0 4px;
  }
`;

const Item = styled.div<{ isDisabled: boolean }>`
  font-family: ${({ theme }) => theme.typography.rubikMedium};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;

  display: flex;
  padding: 8px;
  gap: 8px;
  align-items: center;
  color: ${({ theme }) => theme.colors.dark['01']};
  svg path {
    stroke: ${({ theme }) => theme.colors.dark['02']};
  }

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      color: ${theme.colors.dark['04']};
      cursor: default;
      svg path,
      rect {
        stroke: ${theme.colors.dark['04']};
      }
    `}
  font-family: ${({ theme }) => theme.typography.rubikMedium};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  @media print {
    break-inside: avoid;
    gap: 4px;
    padding: 4px;
  }
`;

const IconWrapper = styled.div`
  @media print {
    svg {
      transform: scale(0.825);
    }
  }
`;

export default function ContentBlock({
  contentBlocks,
  items,
  contentIcons,
  isPrintable,
}: Props) {
  const { t } = useDigitalGuides();

  const contentKeys = Object.keys(items);

  const getIsDisabled = (key: string) => {
    const ids = contentBlocks?.items?.map((item) => item.items.block.body);
    return !ids.includes(key);
  };

  const getIcon = (key: string) => {
    const Icon = getIconFromKey(contentIcons, key);
    return (
      Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )
    );
  };

  const getTranslations = (key: string) => {
    return items[key as keyof typeof items];
  };

  if (!contentBlocks) return null;

  return (
    <Block title={t('digitalguides.blocks')}>
      <List>
        {contentKeys.map((key) => {
          if (getIsDisabled(key) && isPrintable) return null;

          return (
            <Item key={key} isDisabled={getIsDisabled(key)}>
              {getIcon(key)}
              <PrintableBody2>{t(getTranslations(key))}</PrintableBody2>
            </Item>
          );
        })}
      </List>
    </Block>
  );
}
