import { isEqual } from './aux-functions';
import { Roles } from './types/roles';

declare const window: any;

export type InlineManualUser = {
  uid: string;
  username: string;
  name: string;
  surname: string;
  roles: Roles[];
  group: string | null;
  uuid: string;
  crmid: string | null;
  language: string;
  region: string;
  os?: 'ios' | 'android' | undefined;
};

// THE TIMEOUT IS PART OF THE GET STARTED DOCUMENTATION
const createInlineManualPlayer = async (user: InlineManualUser) => {
  if (!window) return;
  await new Promise((resolve) => {
    let retries = 0;
    const timer = setInterval(function () {
      initLanguage(user.language);
      setPeopleTracking(user);
      if (typeof window.createInlineManualPlayer !== 'undefined') {
        window.createInlineManualPlayer(window.inlineManualPlayerData);
        clearInterval(timer);
        resolve(null);
      } else if (retries > 100) {
        clearInterval(timer);
      }
      retries++;
    }, 50);
    return timer;
  });
};

export const initOrUpdateInlineManualPlayer = async (
  user: InlineManualUser
) => {
  if (!window) return;
  const hasToUpdate = !!window.inlineManualTracking;

  if (hasToUpdate) {
    updateInlineManualData(user);
  } else {
    await createInlineManualPlayer(user);
  }
};

const initLanguage = (lang: string) => {
  const language = lang.split('_')[0];
  window.inlineManualOptions = { language };
};

export const setLanguage = (language: string) => {
  const lang = language.split('_')[0];
  window.inline_manual_player.setLanguage(lang);
};

function userChanged(user: InlineManualUser) {
  const inlineUser = window.inlineManualTracking;
  return !isEqual(user, inlineUser);
}

export const updateInlineManualData = (user: InlineManualUser) => {
  if (!window) return;
  if (userChanged(user)) {
    setPeopleTracking(user);
    updateInlinePlayer();
    setLanguage(user.language);
  }
};

export const updateInlinePlayer = () => {
  window.inline_manual_player?.update();
};

const setPeopleTracking = (user: InlineManualUser) => {
  window.inlineManualTracking = {
    uid: user.uid,
    username: user.username,
    name: user.name,
    surname: user.surname,
    roles: user.roles,
    group: user.group,
    uuid: user.uuid,
    crmid: user.crmid,
    language: user.language,
    region: user.region,
  };
};

export function activateTopic(value: string | number) {
  window.inline_manual_player.activateTopic(value);
}

export function deleteInlineManualUser() {
  window.inline_manual_player.destroy();
  delete window.inlineManualTracking;
}

export function activateSessionDoneTopic() {
  activateTopic(102065);
}
