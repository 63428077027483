import { useMutation } from '@tanstack/react-query';
import { AuthError } from '../utils/AuthError';
import { cleverLoginRequest, fromExternalLoginRequest } from '../utils/api';
import {
  openSignInWindow,
  sendMessageToParent,
} from '../utils/windowComunication';
import { CleverTokenResponse, LoginHookProps } from '../types/login';
import { useEffect } from 'react';

export function useCleverLogin({
  dispatch,
  credentials,
  autologin,
  usingRedirect,
}: LoginHookProps) {
  const redirectTo = window.location.origin;
  const { mutate: getCleverToken } = useMutation({
    mutationFn: async (code: string) =>
      cleverLoginRequest(code, redirectTo, credentials),
  });

  const { mutate } = useMutation({
    mutationFn: (token: string) =>
      fromExternalLoginRequest(token, 'clever_token', credentials),
  });

  // this code will be added in cleverPopUp and we need to send the code in the url to the parent
  const searchParams = new URLSearchParams(document.location.search);
  const code = searchParams.get('code');

  sendMessageToParent(code);

  const handleCleverLoginSuccess = (cleverResponse: CleverTokenResponse) => {
    mutate(cleverResponse.access_token, {
      onSuccess(loginResponse) {
        dispatch({
          type: 'SET_LOGIN_INFO',
          payload: {
            loggedFrom: { type: 'clever' },
            response: loginResponse,
          },
        });
      },
      onError: () =>
        handleError('Error when trying to login users with clever token'),
    });
  };

  const handleError = (detail: string) => {
    const error = new AuthError({
      title: 'clever_error',
      detail,
    });
    dispatch({ type: 'SET_ERROR', payload: error });
    handleSetLoading(false);
  };

  const handleSetLoading = (loading: boolean) => {
    dispatch({
      type: 'SET_IS_LOADING',
      payload: {
        loading,
        key: { type: 'clever' },
      },
    });
  };

  const handleLogin = () => {
    const { cleverClientId, cleverDistrictId } = credentials;
    const redirectTo = window.location.origin;
    const redirectUri = encodeURIComponent(redirectTo);

    let url = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirectUri}&client_id=${cleverClientId}`;

    if (cleverDistrictId) {
      url = `${url}&district_id=${cleverDistrictId}`;
    }

    if (usingRedirect) {
      // Save isPressed to true in local storage for when using redirect
      localStorage.setItem('isButtonPressedClever', 'true');
      window.location.href = url;
    } else openSignInWindow(url, 'ModalPopUp', receiveMessage);
  };

  const hasCode = new URL(window.location.href).searchParams.has('code');
  const isButtonPressed =
    localStorage.getItem('isButtonPressedClever') === 'true';

  const receiveMessage = (event: any) => {
    const { data } = event;
    const urlParams = new URLSearchParams(data);
    const codeParam = urlParams.get('code');

    if (codeParam) {
      handleSetLoading(true);
      getCleverToken(codeParam, {
        onSuccess: handleCleverLoginSuccess,
        onError: () =>
          handleError('Error when trying to login to oauth/tokens'),
      });
    }
  };

  useEffect(() => {
    if (isButtonPressed) {
      localStorage.setItem('isButtonPressedClever', 'false');
      if (hasCode) {
        receiveMessage({ data: window.location.search });
      }
    }
  }, []);

  useEffect(() => {
    if (autologin) {
      handleLogin();
    }
  }, [autologin]);

  return handleLogin;
}
