import styled from '@emotion/styled';

const grid = {
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1440,
  },
  columns: {
    xs: 4,
    sm: 8,
    md: 12,
    lg: 12,
    xl: 12,
  },
  glutter: {
    xs: 16,
    sm: 16,
    md: 16,
    lg: 24,
    xl: 24,
  },
  padding: {
    xs: 16,
    sm: 24,
    md: 32,
    lg: 60,
    xl: 0,
  },
};

type ColumnProps = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

type RowProps = {
  justifyContent?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
};

type MediaBreakpoint = keyof typeof grid.breakpoints;

const Row = styled.div<RowProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  width: 100%;
`;

export const getWidth = (size: number | undefined, columns: number): string => {
  if (!size) return '100%';
  const width = (size / columns) * 100;
  return `${Math.min(width, 100)}%`;
};

export const getContainerStyles = (): string => {
  const { breakpoints, glutter, padding } = grid;

  let style =
    'margin: 0 auto; max-width: 1440px; padding-left: 0; padding-right: 0; ';

  Object.keys(breakpoints).forEach((key) => {
    style += `@media (min-width: ${breakpoints[key as MediaBreakpoint]}px) {
      padding-left: ${
        padding[key as MediaBreakpoint] - glutter[key as MediaBreakpoint] / 2
      }px;
      padding-right: ${
        padding[key as MediaBreakpoint] - glutter[key as MediaBreakpoint] / 2
      }px;
    }`;
  });

  return style;
};

export const getColMedia = (sizes: ColumnProps) => {
  const { breakpoints } = grid;

  let style = '';

  Object.entries(breakpoints).forEach(([key, value]) => {
    style += `@media (min-width: ${value}px) {
      width: ${getColWidth(key as MediaBreakpoint, sizes)};
      padding: 0 ${grid.glutter[key as MediaBreakpoint] / 2}px;
    }`;
  });

  return style;
};

export const getColWidth = (
  actualMedia: MediaBreakpoint,
  sizes: ColumnProps
): string => {
  const sizesArray = Object.keys(sizes);

  if (sizes[actualMedia]) {
    return getWidth(sizes[actualMedia], grid.columns[actualMedia]);
  }

  for (let i = sizesArray.indexOf(actualMedia); i >= 0; i--) {
    const key = sizesArray[i];
    const size = sizes[key as MediaBreakpoint];
    if (size) {
      return getWidth(size, grid.columns[actualMedia]);
    }
  }

  return '100%';
};

Grid.Container = styled.div<{ gap?: number }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  gap: ${({ gap }) => gap || 0}px;
  ${getContainerStyles()}
`;
Grid.Col = styled.div<ColumnProps>`
  flex: 0 0 auto;
  ${({ xs, sm, md, lg, xl }) => getColMedia({ xs, sm, md, lg, xl })}
`;

Grid.Row = Row;

export function Grid(): null {
  return null;
}
