import {
  Button,
  Dropdown,
  Typography,
  usePortal,
} from '@innovamat/glimmer-components';
import {
  Roles,
  getMainRole,
  hasAdvisorRole,
  hasDistrictAdminRole,
  hasOwnerRole,
} from '@innovamat/radiance-utils';
import { useUser } from '../../../../../user-management';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const CustomButton = styled(Button)`
  padding-right: 8px;
  padding-left: 8px;
`;

type Props = {
  onUpdateRole: (roles: Roles[]) => void;
  teacherRoles: Roles[] | null;
  isDisabled: boolean;
};

type RoleOption = {
  value: string;
  name: string;
};

const rolesMatrix = {
  [Roles.OWNER]: [Roles.OWNER, Roles.ADMIN, Roles.TEACHER],
  [Roles.ADMIN]: [Roles.ADMIN, Roles.TEACHER],
  [Roles.TEACHER]: [Roles.TEACHER],
};

function SchoolTeachersTableRolesCell({
  onUpdateRole,
  teacherRoles,
  isDisabled,
}: Props): JSX.Element {
  const { user } = useUser();
  const { t } = useTranslation();

  const handleSelectRole = (role: Roles): void => {
    onUpdateRole(rolesMatrix[role]);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const { createPortal } = usePortal({
    containerRef: containerRef,
    rootElement: document.body,
    zIndex: 30,
  });

  const rolesTranslationsKeys = useMemo(() => {
    return {
      [Roles.OWNER]: t('roles.owner'),
      [Roles.ADMIN]: t('roles.admin'),
      [Roles.TEACHER]: t('roles.teacher'),
    };
  }, [t]);

  const isAdvisorOrDistrictAdmin =
    hasAdvisorRole(user?.roles) || hasDistrictAdminRole(user?.roles);

  const rolesOptions: RoleOption[] = useMemo(() => {
    const roles: RoleOption[] = [
      {
        value: Roles.ADMIN,
        name: rolesTranslationsKeys[Roles.ADMIN],
      },
      {
        value: Roles.TEACHER,
        name: rolesTranslationsKeys[Roles.TEACHER],
      },
    ];

    if (isAdvisorOrDistrictAdmin) {
      roles.push({
        value: Roles.OWNER,
        name: rolesTranslationsKeys[Roles.OWNER],
      });
    }

    return roles;
  }, [isAdvisorOrDistrictAdmin, rolesTranslationsKeys]);

  const mainRole = getMainRole(teacherRoles);

  const valueLabel = rolesOptions.find(
    (option) => option.value === mainRole
  )?.name;

  const isSelectDisabled = hasOwnerRole(teacherRoles);

  if (teacherRoles === null) {
    return (
      <Button
        size={'M'}
        variant={'accent'}
        rightIcon="ExpandMoreIcon"
        disabled
        oneLine
      >
        <Typography.Body2>{t('roles.teacher')}</Typography.Body2>
      </Button>
    );
  }

  if (mainRole && (isDisabled || hasOwnerRole(teacherRoles))) {
    return (
      <Button size={'M'} variant={'tertiary'} disabled oneLine>
        <Typography.Body2>{rolesTranslationsKeys[mainRole]}</Typography.Body2>
      </Button>
    );
  }

  return (
    <Dropdown closeOnSelectItem disabled={isSelectDisabled}>
      <div ref={containerRef}>
        <Dropdown.Toggle>
          <CustomButton
            size={'M'}
            variant={'tertiary'}
            rightIcon="ExpandMoreIcon"
            oneLine
          >
            <Typography.Body2>{valueLabel}</Typography.Body2>
          </CustomButton>
        </Dropdown.Toggle>
      </div>
      {createPortal(
        <Dropdown.Content position={'bottomRight'}>
          {rolesOptions.map((option) => (
            <Dropdown.Item
              key={option.value}
              state={option.value === mainRole ? 'selected' : 'active'}
              onSelectItem={() => handleSelectRole(option.value as Roles)}
            >
              {option.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Content>
      )}
    </Dropdown>
  );
}

export { SchoolTeachersTableRolesCell };
