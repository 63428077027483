/* eslint-disable @nx/enforce-module-boundaries */
import { useEffect, useMemo, useRef } from 'react';
import { TFunction } from 'i18next';
import styled from '@emotion/styled';
import {
  Chip,
  FileNameButton,
  FloatingWidget,
} from '@innovamat/glimmer-components';

import { useMedia } from '@innovamat/hooks';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { useGetCSVImports, statusToSemantic } from '@innovamat/ga-features';
import type { CSVImport } from '@innovamat/ga-features';

const Details = styled.div`
  max-height: 110px;
  overflow: auto;
  padding: 8px 20px 8px 4px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

type Props = {
  organizationId: string | undefined;
  isAdminOrOwner: boolean;
  translate: TFunction<'translation', undefined, 'translation'>;
  onUpdateManager?: () => void;
};

export function ImportsWidget({
  organizationId,
  isAdminOrOwner,
  translate,
  onUpdateManager,
}: Props) {
  const {
    data: imports,
    isSuccess,
    isLoading,
  } = useGetCSVImports({
    organizationId: organizationId,
    enabled: isAdminOrOwner,
  });

  const path = useLocation().pathname;
  const isManagerPage = path.includes('school');

  const isHidden = useMedia(960);

  const filterByToday = (importItem: CSVImport) => {
    return new Date(importItem.createdAt).getDate() === new Date().getDate();
  };

  const todayImports = useMemo(
    () => imports?.filter(filterByToday) || [],
    [imports]
  );
  const showImports = localStorage.getItem('hideImportsWidget') !== 'true';

  const processingImports = todayImports.filter(
    (item) => item.status === 'processing' || item.status === 'queued'
  );

  const prevImportsLength = useRef<number>(0);

  if (prevImportsLength.current === 0 && todayImports.length !== 0) {
    prevImportsLength.current = todayImports.length;
  }

  const handleShowWidget = () => {
    const newImports = prevImportsLength.current < todayImports.length;
    const noProcessingImports = processingImports.length === 0;
    const importsLength = todayImports.length;

    if (!noProcessingImports || newImports) {
      localStorage.setItem('hideImportsWidget', 'false');
    }

    if (importsLength === 0 || !isAdminOrOwner) {
      localStorage.setItem('hideImportsWidget', 'true');
    }
  };

  useEffect(() => {
    handleShowWidget();

    if (isSuccess && imports) {
      onUpdateManager?.();
    }
  }, [isSuccess, imports, isAdminOrOwner]);

  const handleClose = () => {
    prevImportsLength.current = todayImports.length;
    if (processingImports.length === 0) {
      localStorage.setItem('hideImportsWidget', 'true');
    }
  };

  if (isHidden) return null;

  if (isLoading || !showImports) return null;

  return (
    <FloatingWidget
      onClose={() => handleClose()}
      title={translate('imports.floatingWidget.title')}
    >
      <Details>
        {todayImports?.map(({ originalFilename, status }, index) => (
          <div key={index}>
            {isManagerPage ? (
              <NavLink to={'/school/imports'}>
                <FileNameButton fileName={originalFilename} />
              </NavLink>
            ) : (
              <a href={'/manager/school/imports'}>
                <FileNameButton fileName={originalFilename} />
              </a>
            )}
            <Chip
              type={statusToSemantic(status)}
              text={translate(`imports.status.${status}`)}
            />
          </div>
        ))}
      </Details>
    </FloatingWidget>
  );
}
