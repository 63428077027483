import { useEventListener, useWindowSize } from '@innovamat/hooks';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

type Props = {
  text?: HTMLElement | Element | null;
  container?: HTMLElement | Element | null;
  dependencies?: any[];
};

export function useCheckOverflow({ text, container, dependencies }: Props): {
  hasOverflow: boolean;
} {
  const [hasOverflow, setHasOverflow] = useState(false);
  const windowSize = useWindowSize();

  const checkOverflow = useCallback(() => {
    if (!text || !container) return;

    setHasOverflow(text.scrollWidth > container.clientWidth);
  }, [text, container]);

  useEventListener('resize', checkOverflow);

  useEffect(() => {
    checkOverflow();
  }, [checkOverflow, windowSize]);

  useLayoutEffect(() => {
    checkOverflow();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, dependencies || []);

  return { hasOverflow };
}
