import { getAdminColumns } from '../admin-dashboard/utils/get-admin-columns';
import { useTranslation } from 'react-i18next';
import ReportsLoader from '../../components/reports-loader';
import { useAdminDashboardQuery, Stages } from '@innovamat/glow-api-client';
import { getRowSpan } from '../admin-dashboard/utils/preprocess';
import { TableComponent } from '@innovamat/glimmer-components';

type Props = {
  organizationId?: string;
  tabsAreLoaded?: boolean;
  isNewManager?: boolean;
};

export const PrimaryAdminDashboard = ({
  organizationId,
  tabsAreLoaded,
  isNewManager,
}: Props): JSX.Element => {
  const { data: dataPE, isLoading: loadingPE } = useAdminDashboardQuery(
    {
      organizationId: organizationId!,
      stage: 'PE' as Stages,
    },
    { enabled: !!organizationId }
  );
  const { t } = useTranslation();
  const columns = getAdminColumns({ t });
  return (
    <>
      {loadingPE || !tabsAreLoaded ? (
        <ReportsLoader />
      ) : (
        <TableComponent
          id={'ADRE_PRIMARY'}
          columns={columns}
          rows={dataPE?.adminDashboard ? getRowSpan(dataPE.adminDashboard) : []}
          topStickyOffset={isNewManager ? 64 : 72}
          isNewManager={isNewManager}
        />
      )}
    </>
  );
};
