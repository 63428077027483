import { ChangeEvent, ReactNode, createContext, useContext } from 'react';
import { isEmpty } from '@innovamat/radiance-utils';
import { SettingsTabType, User } from '..';
import useSettings from '../hooks/use-settings';

type MySettingsValue = {
  schoolName: string | undefined;
  userEmail: string | undefined;
  showLanguageSelector: boolean;
  settingsValues: { firstName: string; lastName: string; phoneNumber: string };
  phoneCode: string | undefined;
  filteredLangs: { value: string; label: string; key: string }[];
  country: string | undefined;
  language: string;
  password: string;
  confirmPassword: string;
  allPositions: [string, unknown][];
  isPageLoading: boolean;
  isValueEmpty: (value?: any) => boolean;
  isPhoneValid: (phone: any, phoneDial: any) => boolean;
  isPasswordValid: (_password: string) => boolean;
  isConfirmPasswordValid: (_confirmPassword: string) => boolean;
  getConfirmPasswordErrorMessage: () => string | undefined;
  getChecked: (key: string) => boolean;
  updateField: (
    name: 'firstName' | 'lastName' | 'phoneNumber',
    value: string
  ) => void;
  handleInputPhone: (e: any, country: any) => void;
  handleChangeLang: (value: string) => void;
  handleChangePassword: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleChangeConfirmPassword: (
    e: ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  handleChangeCheckbox: (role: string) => void;
  onCancel: () => void;
  onSaveChanges: () => void;
  isSubmitEnabled: boolean;
  isLoading: boolean;
  changeActiveTab: (newTab: SettingsTabType) => void;
  isTabActive: (tab: SettingsTabType) => boolean;
  getTitle: (tab: SettingsTabType) => string;
  getCurrentTitle: () => string;
  showModalSave: boolean;
  onCloseModal: () => void;
  onGoBack: () => void;
  contentDrawerIsOpen: boolean;
  onCloseContentDrawer: () => void;
  showDrawer: boolean;
  isSavingSettings: boolean;
  isSuccessSettings: boolean;
};

const MySettingsContext = createContext<MySettingsValue | undefined>(undefined);

type MySettingsProviderProps = {
  user: User;
  goBack: () => void;
  onSuccess: () => void;
  children: ReactNode;
};

export function MySettingsProvider({
  user,
  goBack,
  children,
  onSuccess,
}: MySettingsProviderProps): JSX.Element {
  const {
    schoolName,
    userEmail,
    showLanguageSelector,
    settingsValues,
    phoneCode,
    filteredLangs,
    country,
    language,
    password,
    confirmPassword,
    allPositions,
    isPageLoading,
    isPhoneValid,
    isPasswordValid,
    isConfirmPasswordValid,
    getConfirmPasswordErrorMessage,
    getChecked,
    updateField,
    handleInputPhone,
    handleChangeLang,
    handleChangePassword,
    handleChangeConfirmPassword,
    handleChangeCheckbox,
    onCancel,
    onSaveChanges,
    isSubmitEnabled,
    isLoading,
    changeActiveTab,
    isTabActive,
    getTitle,
    getCurrentTitle,
    showModalSave,
    onCloseModal,
    onGoBack,
    contentDrawerIsOpen,
    onCloseContentDrawer,
    showDrawer,
    isSavingSettings,
    isSuccessSettings,
  } = useSettings({ user, goBack, onSuccess });

  const value: MySettingsValue = {
    schoolName,
    userEmail,
    showLanguageSelector,
    settingsValues,
    phoneCode,
    filteredLangs,
    country,
    language,
    password,
    confirmPassword,
    allPositions,
    isPageLoading,
    isValueEmpty: isEmpty,
    isPhoneValid,
    isPasswordValid,
    isConfirmPasswordValid,
    getConfirmPasswordErrorMessage,
    getChecked,
    updateField,
    handleInputPhone,
    handleChangeLang,
    handleChangePassword,
    handleChangeConfirmPassword,
    handleChangeCheckbox,
    onCancel,
    onSaveChanges,
    isSubmitEnabled,
    isLoading,
    changeActiveTab,
    isTabActive,
    getTitle,
    getCurrentTitle,
    showModalSave,
    onCloseModal,
    onGoBack,
    contentDrawerIsOpen,
    onCloseContentDrawer,
    showDrawer,
    isSavingSettings,
    isSuccessSettings,
  };

  return (
    <MySettingsContext.Provider value={value}>
      {children}
    </MySettingsContext.Provider>
  );
}

export function useMySettings(): MySettingsValue {
  const context = useContext(MySettingsContext);
  if (context === undefined) {
    throw new Error('useMySettings must be used within a MySettingsProvider');
  }
  return context;
}
