import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IconType } from '@innovamat/glimmer-icons';
import { IconBase } from '../IconBase';
import { Tooltip } from '../Tooltip';

enum Sizes {
  XS = 16,
  S = 24,
  M = 32,
  L = 40,
  XL = 64,
}

export type ButtonSize = keyof typeof Sizes;

type IconButtonProps = {
  onClick?: (event: MouseEvent) => void;
  icon: IconType;
  state?: 'inUse' | 'pressed' | 'disabled';
  iconSize?: ButtonSize;
  size?: ButtonSize;
  isRounded?: boolean;
  className?: string;
  tooltipText?: string;
  dataTestId?: string;
  iconColor?: string;
} & React.HTMLAttributes<HTMLButtonElement>;

type StyledProps = {
  state?: 'inUse' | 'pressed' | 'disabled';
  buttonSize?: string;
  isRounded?: boolean;
};

const StyledIconButton = styled.button<StyledProps>`
  border: none;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;

  ${({ isRounded, theme }) =>
    isRounded &&
    css`
      border-radius: 50%;
      background-color: ${theme.tokens.color.alias.cm.surface['surface-primary']
        .value};
      box-shadow: ${theme.elevations['01']};
    `};

  svg,
  path {
    transition: all 0.3s ease 0.3s;
  }

  ${({ state, theme }) =>
    state === 'disabled' &&
    css`
      user-select: none;
      pointer-events: none;
      cursor: default;

      svg,
      path {
        fill: ${theme.tokens.color.alias.cm.icon['icon-disabled'].value};
        transition: all 0.3s ease;
      }
    `};
  ${({ state, theme }) =>
    state === 'pressed' &&
    css`
      background-color: ${theme.tokens.color.specific['state-layer'][
        'state-press-darker'
      ].value};
    `};
  ${({ state, theme }) =>
    state === 'inUse' &&
    css`
      background-color: ${theme.tokens.color.alias.cm.bg['bg-warning'].value};
      box-shadow: ${theme.elevations['01']};
      svg,
      path {
        fill: ${theme.tokens.color.alias.cm.icon['icon-warning'].value};
        transition: all 0.3s ease;
      }
    `};
`;

export function IconButton({
  state,
  size = 'L',
  iconSize,
  isRounded = false,
  icon,
  tooltipText,
  dataTestId,
  iconColor,
  ...rest
}: IconButtonProps): JSX.Element {
  return (
    <Tooltip
      content={tooltipText}
      disabled={state === 'disabled'}
      popperOptions={{ strategy: 'fixed' }}
    >
      <StyledIconButton
        {...rest}
        state={state}
        disabled={state === 'disabled'}
        isRounded={isRounded}
        data-testid={dataTestId || 'icon-button'}
      >
        <IconBase
          iconColor={iconColor}
          icon={icon}
          iconSize={iconSize}
          size={size}
          isRounded={isRounded}
        />
      </StyledIconButton>
    </Tooltip>
  );
}
