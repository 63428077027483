/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { authService, storage } from '@innovamat/radiance-utils';
import axios, { AxiosRequestConfig } from 'axios';

export const authAxiosInstance = axios.create();
export const axiosInstance = axios.create();

authAxiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = storage.tokenInfo.get()?.access_token;
    const locale = storage.language.get();

    if (authService.isTokenExpiringSoon(token)) {
      try {
        return check(config);
      } catch (error) {
        storage.clear();
        window.location.href = '/';
        Promise.reject(error);
      }
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
        'Accept-Language': config.headers?.['Accept-Language'] || locale,
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    } as any;
  },
  (error) => {
    Promise.reject(error);
  }
);

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, _token: any = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(_token);
    }
  });

  failedQueue = [];
};

const check = (originalRequest: any): any => {
  if (!originalRequest._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token: any) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return authAxiosInstance(originalRequest);
        })
        .catch((_err: any) => Promise.reject(_err));
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise((resolve, reject) => {
      authService
        .refreshToken()
        .then((newTokensInfo) => {
          authAxiosInstance.defaults.headers.common.Authorization = `Bearer ${newTokensInfo.access_token}`;
          originalRequest.headers.Authorization = `Bearer ${newTokensInfo.access_token}`;

          processQueue(null, newTokensInfo.access_token);
          resolve(authAxiosInstance(originalRequest));
        })
        .catch((_err: any) => {
          processQueue(_err, null);
          storage.clear();
          window.location.href = '/';
          reject(_err);
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }
};

authAxiosInstance.interceptors.response.use(
  (response) => {
    const originalRequest: any = response.config;
    const errors = response.data?.errors;

    if (errors) {
      if (
        errors.some(
          (error: any) =>
            error.extensions?.response?.body.type === 'auth.expired_jwt' ||
            error.extensions?.response?.body.message === 'Expired JWT'
        )
      ) {
        return check(originalRequest);
      }
    }
    return response;
  },
  (err) => {
    const originalRequest = err.config;

    if (err.response?.status === 401) {
      return check(originalRequest);
    }

    return Promise.reject(err);
  }
);
