export enum Roles {
  ADMIN = 'ROLE_ADMIN',
  OWNER = 'ROLE_OWNER',
  TEACHER = 'ROLE_TEACHER',
  STUDENT = 'ROLE_STUDENT',
  LEAD = 'ROLE_LEAD',
  FREE = 'ROLE_FREEUSER',
  PARENT = 'ROLE_PARENT',
  ADVISOR = 'ROLE_ADVISOR',
  DISTRICT_ADMIN = 'ROLE_DISTRICT_ADMIN',
  CONTENT_ADMIN = 'ROLE_CONTENT_ADMIN',
  INTERNAL = 'ROLE_INTERNAL',
  STUDENT_JUNIOR = 'ROLE_STUDENT_JUNIOR',
}

export const hasTeacherRole = (roles: Roles[] | undefined | null) => {
  return Boolean(roles?.includes(Roles.TEACHER));
};

export const hasOwnerRole = (roles: Roles[] | undefined | null) => {
  return Boolean(roles?.includes(Roles.OWNER));
};

export const hasAdminRole = (roles: Roles[] | undefined | null) => {
  return Boolean(roles?.includes(Roles.ADMIN));
};

export const hasStudentRole = (roles: Roles[] | undefined | null) => {
  return Boolean(roles?.includes(Roles.STUDENT));
};

export const hasStudentJuniorRole = (roles: Roles[] | undefined | null) => {
  return Boolean(roles?.includes(Roles.STUDENT_JUNIOR));
};

export const hasLeadRole = (roles: Roles[] | undefined | null) => {
  return Boolean(roles?.includes(Roles.LEAD));
};

export const hasFreeRole = (roles: Roles[] | undefined | null) => {
  return Boolean(roles?.includes(Roles.FREE));
};

export const hasParentRole = (roles: Roles[] | undefined | null) => {
  return Boolean(roles?.includes(Roles.PARENT));
};

export const hasDistrictAdminRole = (roles: Roles[] | undefined | null) => {
  return Boolean(roles?.includes(Roles.DISTRICT_ADMIN));
};

export const hasAdvisorRole = (roles: Roles[] | undefined | null) => {
  return Boolean(roles?.includes(Roles.ADVISOR));
};

export const hasContentAdminRole = (roles: Roles[] | undefined | null) => {
  return Boolean(roles?.includes(Roles.CONTENT_ADMIN));
};

export const hasInternalRole = (roles: Roles[] | undefined | null) => {
  return Boolean(roles?.includes(Roles.INTERNAL));
};

export const getMainRole = (roles: Roles[] | undefined | null) => {
  if (hasOwnerRole(roles)) {
    return Roles.OWNER;
  }
  if (hasAdminRole(roles)) {
    return Roles.ADMIN;
  }
  if (hasAdvisorRole(roles)) {
    return Roles.ADVISOR;
  }
  if (hasDistrictAdminRole(roles)) {
    return Roles.DISTRICT_ADMIN;
  }
  if (hasTeacherRole(roles)) {
    return Roles.TEACHER;
  }
  return roles ? roles[0] : null;
};

export const hasManagerRoles = (roles: Roles[] | undefined | null) => {
  return (
    hasTeacherRole(roles) ||
    hasOwnerRole(roles) ||
    hasAdminRole(roles) ||
    hasAdvisorRole(roles) ||
    hasDistrictAdminRole(roles)
  );
};
