import { css } from '@emotion/react';
import type { SerializedStyles, Theme } from '@emotion/react';

type ElevationType = keyof Theme['tokens']['elevation'];

const getElevation = (
  theme: Theme,
  searchedElevation: ElevationType
): SerializedStyles => {
  const { x, y, blur, spread, color } =
    theme.tokens.elevation[searchedElevation].value;

  return css`
    box-shadow: ${x}px ${y}px ${blur}px ${spread}px ${color};
  `;
};

export { getElevation };

export type { ElevationType };
