import { useQueryParams } from '@innovamat/hooks';
import { defaultRegion } from '@innovamat/localization';
import { useUser } from './use-user';

export default function useGetUserRegion() {
  const query = useQueryParams();
  const { region } = useUser();

  return query.get('r') || query.get('region') || region || defaultRegion;
}
