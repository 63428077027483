import { useEffect, useState } from 'react';
import { useRosteringsQuery } from '@innovamat/glow-api-client';
import { CSVImport } from '../types';

type Props = {
  organizationId: string | undefined;
  enabled?: boolean;
};

const REFETCH_INTERVAL = 30000;

function useGetCSVImports({ organizationId, enabled = true }: Props) {
  const [refetchInterval, setRefetchInterval] = useState(REFETCH_INTERVAL);

  const query = useRosteringsQuery(
    {
      organizationId: organizationId!,
    },
    {
      enabled: enabled && !!organizationId,
      refetchInterval: refetchInterval,
    }
  );

  useEffect(() => {
    if (query.isError) {
      setRefetchInterval(0);
    }
  }, [query.isError]);

  return { ...query, data: query.data?.rosterings as CSVImport[] | undefined };
}

export { useGetCSVImports };
