import i18next from 'i18next';
import { DateTime } from 'luxon';

type DayMonthFormat = 'LL/dd' | 'dd/LL';
type DateFormat = 'yyyy/LL/dd' | 'dd/LL/yyyy';

export const getDayMonthDateFormat = () => {
  const language = i18next.language;
  switch (language) {
    case 'eu':
    case 'en_US':
    case 'en':
      return 'LL/dd';
    default:
      return 'dd/LL';
  }
};

export const getDateFormat = (): DateFormat => {
  const language = i18next.language;
  switch (language) {
    case 'eu':
    case 'en_US':
    case 'en':
      return 'yyyy/LL/dd';
    default:
      return 'dd/LL/yyyy';
  }
};

export const getWeekStartDay = () => {
  const language = i18next.language;
  switch (language) {
    case 'eu':
    case 'en_US':
    case 'en':
      return 0;
    default:
      return 1;
  }
};

export const getFormattedDate = (
  date: string | null | undefined | Date,
  format?: DateFormat
) => {
  if (!date || date === 'Invalid Date') return '';
  let ISODate = '';
  if (date instanceof Date) {
    if (isNaN(date.getTime())) return '';
    ISODate = date.toISOString();
  } else {
    ISODate = date;
  }
  return DateTime.fromISO(ISODate).toFormat(format || getDateFormat());
};

export const getFormattedDayMonthDate = (
  date: string | null | undefined,
  format?: DayMonthFormat
) => {
  if (!date) return '';
  return DateTime.fromISO(date).toFormat(format || getDayMonthDateFormat());
};

export const formatDateFromSql = (
  date: string | null | undefined,
  format?: DateFormat
) => {
  if (!date) return '';
  return DateTime.fromSQL(date).toFormat(format || getDateFormat());
};

const daysAgo = (date: string) => {
  const now = DateTime.local();
  const past = DateTime.fromSQL(date);
  const diff = now.diff(past, 'day');
  return Math.floor(diff.days);
};

export const nextYear = (date: string): string | null => {
  return DateTime.fromSQL(date).plus({ years: 1 }).toISODate();
};

export const getTimeAgo = (date: string | null | undefined) => {
  if (!date) return i18next.t('common.timeAgo.never');

  const days = daysAgo(date);

  if (days === 0) return i18next.t('common.timeAgo.today');
  if (days === 1) return i18next.t('common.timeAgo.yesterday');
  if (days >= 365) return i18next.t('common.timeAgo.yearAgo');
  if (days >= 30) return i18next.t('common.timeAgo.monthAgo');
  return DateTime.fromSQL(date).toFormat('dd/LL/yyyy');
};

// format timestamp ISO 8601 - YYYY-MM-DDTHH:mm:ss+/-hh:mm to dd/mm/yyyy hh:mm
export const getFormattedTimeStamp = (timestamp: string) => {
  const dateObj = new Date(timestamp);

  const day = dateObj.getUTCDate();
  const month = dateObj.getUTCMonth() + 1;
  const year = dateObj.getUTCFullYear();

  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();

  const formattedDate = `${day}/${month}/${year}`;
  const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}h`;

  return `${formattedDate}. ${formattedTime}`;
};

export const getNextSunday = (): Date => {
  const now = DateTime.now();
  const sunday = now.startOf('week').plus({ days: 7 });
  return sunday.toJSDate();
};

export const getPrevSunday = (): Date => {
  const now = DateTime.now();
  const sunday = now.startOf('week');
  return sunday.toJSDate();
};
